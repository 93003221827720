import { createSlice } from "@reduxjs/toolkit";
import { sucessToast } from "../toast/toast";

import { SendOtpService } from "../service/sendOtpService";
const initialState = {
  loading: false,
  error: null,
};

const SendOTPslice = createSlice({
  name: "SendOTPslice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(SendOtpService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(SendOtpService.fulfilled, (state, action) => {
      return { ...state,  loading: false };
    });
    builder.addCase(SendOtpService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default SendOTPslice.reducer;

