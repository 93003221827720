import { toast } from "react-toastify";

export const handleError = (err) => {
  if (err.response.data) {
    toast.error(err.response.data);
  } else if (err.response.data.errors) {
    err.response.data.errors.map((d, i) => {
      toast.error(d.msg);
    });
  } else {
    toast.error('Something went wrong');
  }
};