import {combineReducers} from 'redux'
import loginslice from '../slice/loginslice'
import getDashboardslice from '../slice/getDashboardslice'
import getAllorderSlice from '../slice/getAllorderSlice'
import addcarrierslice from '../slice/addcarrierslice'
import addCustomerSlice from '../slice/addCustomerSlice'
import getAllcustomerSlice from '../slice/getAllcustomerSlice'
import getAlluserSlice from '../slice/getAlluserSlice'
import getAllorderTrackingSlice from '../slice/getAllorderTrackingSlice'
import addCreditSlice from '../slice/addCreditSlice'
import getAllCreditmanagementSlice from '../slice/getAllCreditmanagementSlice'
import edithistorySlice from '../slice/edithistorySlice'
import editCustomerSlice from '../slice/editCustomerSlice'
import updateUserSlice from '../slice/updateUserSlice'
import getCarrierByIdSlice from '../slice/getCarrierByIdSlice'
import updateCarrierSlice from '../slice/updateCarrierSlice'
import getCustomerByIdSlice from '../slice/getCustomerByIdSlice'
import getAllorderManagementSlice from '../slice/getAllorderManagementSlice'
import addNewClientSlice from '../slice/addNewClientSlice'
import getClientbyIdslice from '../slice/getClientbyIdslice'
import addCreditsDistributorSlice from '../slice/addCreditsDistributorSlice'
import addCreditsForCustomerSlice from '../slice/addCreditsForCustomerSlice'
import updateOrderStatusSlice from '../slice/updateOrderStatusSlice'
import udpateDistributorOrderSlice from '../slice/udpateDistributorOrderSlice'
import addNewSimOrderSlice from '../slice/addNewSimOrderSlice'
import newGetAllcustomerOrderSlice from '../slice/newGetAllcustomerOrderSlice'
import getWeekDashSlice from '../slice/getWeekDashSlice'
import getOrderDataByIdSlice from '../slice/getOrderDataByIdSlice'

import getAllCarrrierByDisIdSlice from '../slice/getAllCarrrierByDisIdSlice'
import updatePriceSlice from '../slice/updatePriceSlice'
import getCustomerPriceslice from '../slice/getCustomerPriceslice'
import getAllAvailableNameSlice from '../slice/getAllAvailableNameSlice'
import getAllDistributerOrdersBySlice from '../slice/getAllDistributerOrdersBySlice'
import deleteCarrierByIdSlice from '../slice/deleteCarrierByIdSlice'
import deleteCustomerSlice from '../slice/deleteCustomerSlice'
import deletedistributorSlice from '../slice/deletedistributorSlice'
import deletOrderSlice from '../slice/deletOrderSlice'
import getReportDataBydistributorSlice from '../slice/getReportDataBydistributorSlice'
import customerOrderTrackingSlice from '../slice/customerOrderTrackingSlice'
import UploadFileSlice from '../slice/UploadFileSlice'
import UploadbulkDistributorSlice from '../slice/UploadbulkDistributorSlice'
import SendOTPslice from '../slice/SendOtpSlice'
import VerifyOtpSlice from '../slice/VerifyOtpSlice'


export default combineReducers({
    loginslice:loginslice,
    getDashboardslice:getDashboardslice,
    getAllorderSlice:getAllorderSlice,
    addcarrierslice:addcarrierslice,
    addCustomerSlice:addCustomerSlice,
    getAllcustomerSlice:getAllcustomerSlice,
    getAlluserSlice:getAlluserSlice,
    getAllorderTrackingSlice:getAllorderTrackingSlice,
    addCreditSlice:addCreditSlice,
    getAllCreditmanagementSlice:getAllCreditmanagementSlice,
    edithistorySlice:edithistorySlice,
    editCustomerSlice:editCustomerSlice,
    updateUserSlice:updateUserSlice,
    getCarrierByIdSlice:getCarrierByIdSlice,
    updateCarrierSlice:updateCarrierSlice,
    getCustomerByIdSlice:getCustomerByIdSlice,
    getAllorderManagementSlice:getAllorderManagementSlice,
    addNewClientSlice:addNewClientSlice,
    getClientbyIdslice:getClientbyIdslice,
    addCreditsDistributorSlice:addCreditsDistributorSlice,
    addCreditsForCustomerSlice:addCreditsForCustomerSlice,
    updateOrderStatusSlice:updateOrderStatusSlice,
    udpateDistributorOrderSlice:udpateDistributorOrderSlice,
    addNewSimOrderSlice:addNewSimOrderSlice,
    newGetAllcustomerOrderSlice:newGetAllcustomerOrderSlice,
    getWeekDashSlice:getWeekDashSlice,
    getOrderDataByIdSlice:getOrderDataByIdSlice,
    getAllCarrrierByDisIdSlice:getAllCarrrierByDisIdSlice,
    updatePriceSlice:updatePriceSlice,
    getCustomerPriceslice:getCustomerPriceslice,
    getAllAvailableNameSlice:getAllAvailableNameSlice,
    getAllDistributerOrdersBySlice:getAllDistributerOrdersBySlice,
    deleteCarrierByIdSlice:deleteCarrierByIdSlice,
    deleteCustomerSlice:deleteCustomerSlice,
    deletedistributorSlice:deletedistributorSlice,
    deletOrderSlice:deletOrderSlice,
    getReportDataBydistributorSlice:getReportDataBydistributorSlice,
    customerOrderTrackingSlice:customerOrderTrackingSlice,
    UploadFileSlice:UploadFileSlice,
    UploadbulkDistributorSlice:UploadbulkDistributorSlice,
    SendOTPslice:SendOTPslice,
    VerifyOtpSlice:VerifyOtpSlice
})