import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { BASE_URL } from "../auth/baseUrl";
import { handleError } from "../utils/handleError";
import { basicAuth } from "../auth/basicauth"; // Import basicAuth from the appropriate location
import { sucessToast } from "../toast/toast";


export const DeleteCarrierByIdService = createAsyncThunk(
  "DeleteCarrierByIdService",
  async (id) => {
    
    try {
      let url = BASE_URL + `deleteCarrierById/${id}`;
      const config = {
        headers: {
          "Authorization": basicAuth, // Include basic authentication header
          'Content-Type': 'application/json' // Set content type to application/json
        }
      };
      const res = await axios.delete(url, config); 
     sucessToast(res?.data)
      return res.data;
    } catch (error) {
      console.log(error)
      handleError(error); // Pass the entire error object to the handleError function

      throw error;
    }
  }
);
