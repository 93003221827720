import { createSlice } from "@reduxjs/toolkit";
import { sucessToast } from "../toast/toast";

import { CustomerOrderTrackingService } from "../service/customerOrderTrackingService";
const initialState = {
  data:[],
  loading: false,
  error: null,
};

const CustomerOrderTrackinSlice = createSlice({
  name: "CustomerOrderTrackinSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(CustomerOrderTrackingService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(CustomerOrderTrackingService.fulfilled, (state, action) => {
      return { ...state,  loading: false,data:action?.payload };
    });
    builder.addCase(CustomerOrderTrackingService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default CustomerOrderTrackinSlice.reducer;

