import React, { useState } from 'react';
import { Stack, Input, FormControl, FormErrorMessage } from '@chakra-ui/react';
import bgImage from '../assets/Rectangle 9.png';
import bgImage2 from '../assets/newRec.png';
import { Link, useNavigate } from 'react-router-dom';
import { LoginService } from '../service/Loginservice';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '@chakra-ui/react';

function Login({onLogin}) {
  const {loading}=useSelector((state)=>state?.loginslice)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState({
    email: "",
    password: ""
  });

  const [errors, setErrors] = useState({
    email: "",
    password: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value
    });
  };

  const validate = () => {
    let tempErrors = { email: "", password: "" };
    let isValid = true;

    if (!data.email) {
      tempErrors.email = "Email is required.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      tempErrors.email = "Email is invalid.";
      isValid = false;
    }

    if (!data.password) {
      tempErrors.password = "Password is required.";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleLogin = () => {
    if (validate()) {
      dispatch(LoginService(data))
        .unwrap()
        .then((res) => {
          localStorage.setItem('adminId', res.adminId);
          localStorage.setItem('adminName', res.name);

          console.log(res,'res')
          onLogin();
        
        })
        .catch((error) => {
          console.error('Login failed:', error);
        });
    }
  };
  

  return (
    <div style={{ minHeight: '100vh', position: 'relative' }}>
      {/* Top-left image */}
      <div className='absolute top-0 left-0 w-[10%]'>
        <img src={bgImage} alt="Background Top Left" />
      </div>

      {/* Bottom-right image */}
      <div className='absolute bottom-0 right-0 w-[10%]'>
        <img src={bgImage2} alt="Background Bottom Right" />
      </div>

      {/* Centered login form */}
      <div className="w-full h-screen flex justify-center items-center flex-col" style={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}>
        <div className="w-[40%] max-[768px]:w-[80%] p-[30px] rounded-md" style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
          <div className="mx-auto">
            <h1 className="text-customBlue text-2xl font-bold flex justify-center">Login</h1>
          </div>
          <Stack spacing={3} className="mt-4">
            <FormControl isInvalid={errors.email}>
              <Input variant='filled' placeholder='Enter Your Email' name="email" onChange={handleChange} />
              {errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
            </FormControl>
            <FormControl isInvalid={errors.password}>
              <Input variant='filled' placeholder='Password' name="password" type="password" onChange={handleChange} />
              {errors.password && <FormErrorMessage>{errors.password}</FormErrorMessage>}
            </FormControl>
            <div className='flex justify-end items-end'>
              <Link to='/numbersystem/ResetOtp'>
              Reset Password
              </Link>
            </div>
            <button onClick={handleLogin} className="bg-customBlue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              {
                loading?(
                  <>
                  <Spinner/>
                  </>
                ):"Login"
              }
            </button>
          </Stack>
        </div>
      </div>
    </div>
  );
}

export default Login;
