import { createSlice } from "@reduxjs/toolkit";
import { sucessToast } from "../toast/toast";
import { UpdateCarrierService } from "../service/updateCarrierService";
const initialState = {
  loading: false,
  error: null,
};

const UpdateCarrierSlice = createSlice({
  name: "UpdateCarrierSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(UpdateCarrierService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(UpdateCarrierService.fulfilled, (state, action) => {
      sucessToast(" Carrier Updated");
      return { ...state,  loading: false };
    });
    builder.addCase(UpdateCarrierService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default UpdateCarrierSlice.reducer;

