import { createSlice } from "@reduxjs/toolkit";

import { UploadDisBulkUploadService } from "../service/disUploadbulkService";
const initialState = {

  data: null,
  loading: false,
  error: null,
  classNotesList: [],
};


const UploadBulkDisSlice = createSlice({
  name: "UploadBulkDisSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(UploadDisBulkUploadService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(UploadDisBulkUploadService.fulfilled, (state, action) => {
      return { ...state,  loading: false };
    });
    builder.addCase(UploadDisBulkUploadService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});
export default UploadBulkDisSlice.reducer;

