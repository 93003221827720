import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { BASE_URL } from "../auth/baseUrl";
import { handleError } from "../utils/handleError";
import { basicAuth } from "../auth/basicauth"; // Import basicAuth from the appropriate location

export const UpdateCarrierService = createAsyncThunk(
  "UpdateCarrierService",
  async (payload) => {
    console.log(payload,'pawannnnnn')
    try {
      const adminId=localStorage.getItem('adminId')
      const data={
        "carrierId": null,
        "areaCode":payload?.data?.areacode,
        "name": payload?.data?.name,
        "status":payload?.data?.status,
        "quantity": payload?.data?.quantity,
        "totalCost": payload?.data?.price,
      "quantity":payload.data?.quantity,
      "altertQuantity":payload?.data?.mquantity,
        "passAdminId": {
            "adminId": adminId
        }
    }
      let url = BASE_URL + `updateCarrierById/${payload?.id}`;
      const config = {
        headers: {
          "Authorization": basicAuth, // Include basic authentication header
          'Content-Type': 'application/json' // Set content type to application/json
        }
      };
      const res = await axios.put(url, data, config); // Pass the config object as the third parameter
      console.log(res)
      return res.data;
    } catch (error) {
      console.log(error)
      handleError(error); // Pass the entire error object to the handleError function

      throw error;
    }
  }
);
