import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { BASE_URL } from "../auth/baseUrl";
import { handleError } from "../utils/handleError";
import { basicAuth } from "../auth/basicauth";

export const AddCreditsForCustomerService = createAsyncThunk(
  "AddCreditsForCustomerService",
  async (payload, thunkAPI) => {
    const { refund } = payload; // Destructure refund from payload
    const { data } = payload; // Destructure refund from payload


    try {
      console.log(refund, 'refund');
       
      const adminId = localStorage.getItem('adminId');

      const ewdata = {
        "description": data.desc,
        "amount": data.amount,
        "createdDate": null,
        "updatedDate": null,
        "status": refund,
        "passCustomer": {
          "customerId": data.clientName
        }
      };

      let url = BASE_URL + 'addManageCreditByCustomerId';
      const config = {
        headers: {
          "Authorization": basicAuth,
          'Content-Type': 'application/json'
        }
      };

      const res = await axios.post(url, ewdata, config);
      console.log(res.data);
      return res.data;
    } catch (error) {
      console.error("Error in AddCreditsForCustomerService:", error);
      handleError(error); // Ensure handleError is defined correctly
      throw error;
    }
  }
);
