import { createSlice } from "@reduxjs/toolkit";
import { sucessToast } from "../toast/toast";

import { getCustomerByIdService } from "../service/getCustomerByIDservice";
const initialState = {
  data: [],
  loading: false,
  error: null,
};

const getCustomerIDSlice = createSlice({
  name: " getCustomerIDSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCustomerByIdService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(getCustomerByIdService.fulfilled, (state, action) => {
      // sucessToast("Broker Data Get Sucessfully");
      return { ...state,  loading: false,data:action.payload };
    });
    builder.addCase(getCustomerByIdService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default getCustomerIDSlice.reducer;

