import { createSlice } from "@reduxjs/toolkit";
import { sucessToast } from "../toast/toast";
import { UpdateOrderStatus } from "../service/updateOrderStatusService";
const initialState = {
  loading: false,
  error: null,
};

const UpdateOrderStatusSlice = createSlice({
name: "UpdateOrderStatusSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(UpdateOrderStatus.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(UpdateOrderStatus.fulfilled, (state, action) => {
      sucessToast(" Order Status Updated");
      return { ...state,  loading: false };
    });
    builder.addCase(UpdateOrderStatus.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default UpdateOrderStatusSlice.reducer;

