import { createSlice } from "@reduxjs/toolkit";
import { sucessToast } from "../toast/toast";

import { AddCreditsForCustomerService } from "../service/addCreditmanageForCustomerService";
const initialState = {
  loading: false,
  error: null,
};

const addCustomercreditsSlice = createSlice({
  name: "addCustomercreditsSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(AddCreditsForCustomerService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(AddCreditsForCustomerService.fulfilled, (state, action) => {
      sucessToast("Credits  Added Sucessfully");
      return { ...state,  loading: false };
    });
    builder.addCase(AddCreditsForCustomerService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default addCustomercreditsSlice.reducer;

