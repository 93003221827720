import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { BASE_URL } from "../auth/baseUrl";
import { handleError } from "../utils/handleError";
import { basicAuth } from "../auth/basicauth"; // Import basicAuth from the appropriate location

export const editHistoryService = createAsyncThunk(
  "editHistoryService",
  async (payload) => {
    try {
      let url = BASE_URL + 'SubAdminLogin';
      const config = {
        headers: {
          "Authorization": basicAuth, // Include basic authentication header
          'Content-Type': 'application/json' // Set content type to application/json
        }
      };
      const res = await axios.post(url, payload, config); // Pass the config object as the third parameter
      console.log(res)
      return res.data;
    } catch (error) {
      console.log(error)
      handleError(error); // Pass the entire error object to the handleError function

      throw error;
    }
  }
);
