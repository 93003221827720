import react, { useEffect, useState } from 'react'
import { Button, FormControl, FormLabel, Input, Select, Spinner } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { AddNewClientService } from '../service/addNewClientService'
import { useDispatch, useSelector } from 'react-redux'
import { getClientByIDservice } from '../service/getClientByIdService'
import { useFetcher, useParams } from 'react-router-dom'
import { UpdateUserService } from '../service/updateUserService'
import { getAllOrderService } from '../service/getAllordderSevice'
import { getAllCarriersPriceByDisService } from '../service/getAllcarrirerPricesDisId'
import { UpdatePriceDisService } from '../service/updatenewPriceservice'
const EditClient = () => {
    const newData = useSelector((state) => state?.getClientbyIdslice?.data)
    const [data, setData] = useState({
        name: "",
        email: "",
        password: "",
        paymentModel: "",
        status: "",
        Phone: "",
        password:""

    })
    const { id } = useParams()

    useEffect(() => {
        dispatch(getClientByIDservice(id))
    }, [id])
    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })

    }
    const dispatch = useDispatch()
    const handleClick = (e) => {
        e.preventDefault()
        const newData = {
            data: data,
            id: id
        }
        dispatch(UpdateUserService(newData))
    }

    useEffect(() => {
        setData({
            name: newData?.distributerName,
            email: newData?.distributerEmail,
            password: "",
            paymentModel: newData?.acountType,
            status: JSON.stringify(newData?.acountStatus),
            Phone: newData?.phoneNumber,
            password:newData?.password
        })
    }, [newData])

    const loading = useSelector((state) => state?.UpdateUserSlice?.loading)
    const AllCarrier = useSelector((state) => state?.getAllCarrrierByDisIdSlice?.data)
    // const load = useSelector((state) => state?.updatePriceSlice?.loading)

    

    useEffect(() => {
        dispatch(getAllCarriersPriceByDisService(id))
    }, [id])
   


    const [carrierPrices, setCarrierPrices] = useState(AllCarrier);
    const [load, setLoading] = useState(false);

    const handlePriceChange = (e, carrier) => {
        const { value } = e.target;
        setCarrierPrices({
            ...carrierPrices,
            [carrier]: value
        });
    };

    const handleSave = async (e, carrier) => {
        e.preventDefault();
        setLoading(true);
        try {
            await dispatch(UpdatePriceDisService({ carrier, price: carrierPrices[carrier] ,id}));
        } catch (error) {
            console.error('Failed to update price:', error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <>

            <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
                <h3 className="font-medium w-[98%] mx-auto text-xl max-[768px]:text-center">Edit Distributor</h3>
            </div>

            <div className='flex mx-auto justify-center items-center h-full mt-4'>
                <div className='rounded-[5px] w-[60%] max-[768px]:w-[90%]' style={{ boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px' }}>
                    <div className='px-4 py-3 text-xl border-b-2' style={{ background: 'rgba(243, 251, 254, 1)' }}>


                        <h3 className='text-[#8A8D56] font-medium'>Edit Distributor</h3>
                    </div>
                    <div className='p-4'>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Name</FormLabel>
                                <Input onChange={handleChange} placeholder='Name' name='name' value={data?.name}></Input>
                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Email</FormLabel>
                                <Input onChange={handleChange} placeholder='Email' name='email' value={data?.email}></Input>

                            </FormControl>

                        </div> <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Phone</FormLabel>
                                <Input onChange={handleChange} placeholder='Phone' name='Phone' type='number' value={data?.Phone}></Input>

                            </FormControl>

                        </div>
                        <div className=''>
                            {/* <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Confirm Password</FormLabel>
                                <Input placeholder=' Confirm Password'></Input>

                            </FormControl> */}

                        </div>

                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Distributer  Type </FormLabel>
                                <Select 
               value={data?.paymentModel==="POST_PAID"?"true":"false"}
               onChange={handleChange}
                  name="paymentModel"
                  >
                       <option value="">
Select
                </option>
                <option value="true">
Postpaid
                </option>
                <option value="false">
                Prepaid
                </option>
               </Select>
                            </FormControl>

                        </div>

                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Status</FormLabel>
                                <Select onChange={handleChange} placeholder='Status' name='status' value={data?.status}>
                                    <option value={'true'}>Active</option>
                                    <option value={'false'}>Inactive</option>


                                </Select>

                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Password</FormLabel>
                                <Input value={data?.password} placeholder='Password' onChange={handleChange} name='password'></Input>

                            </FormControl>

                        </div>
                        <button onClick={handleClick} class="bg-[#8A8D56] hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-3">

                            {
                                loading ? (
                                    <>
                                        <Spinner />
                                    </>
                                ) : (
                                    <>
                                        <AddIcon />  Save Distributer
                                    </>
                                )
                            }
                        </button>
                        <div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default EditClient