import { createSlice } from "@reduxjs/toolkit";
import { sucessToast } from "../toast/toast";
import { UpdateUserService } from "../service/updateUserService";

const initialState = {
  loading: false,
  error: null,
};

const UpdateUserSlice = createSlice({
  name: "UpdateUserSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(UpdateUserService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(UpdateUserService.fulfilled, (state, action) => {
      sucessToast("Distributer Updated Sucessfully");
      return { ...state,  loading: false };
    });
    builder.addCase(UpdateUserService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default UpdateUserSlice.reducer;

