import react, { useEffect, useState } from 'react'
import { Button, FormControl, FormLabel, Input, Select, Spinner } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { AddNewClientService } from '../service/addNewClientService'
import { useDispatch, useSelector } from 'react-redux'
import { getClientByIDservice } from '../service/getClientByIdService'
import { useFetcher, useParams } from 'react-router-dom'
import { UpdateUserService } from '../service/updateUserService'
import { getAllOrderService } from '../service/getAllordderSevice'
import { getAllCarriersPriceByDisService } from '../service/getAllcarrirerPricesDisId'
import { UpdatePriceDisService } from '../service/updatenewPriceservice'
const EditPriceDis = () => {
    const AllCarrier = useSelector((state) => state?.getAllCarrrierByDisIdSlice?.data)
     const {id}=useParams()
// 

    useEffect(() => {
        dispatch(getAllCarriersPriceByDisService(id))
    }, [id])

  

    
    const [loading, setLoading] = useState(false);
    const [price, setPrice] = useState('');
    const [currentCarrier, setCurrentCarrier] = useState('');
    const dispatch = useDispatch();

    const handlePriceChange = (e) => {
        setPrice(e.target.value);
    };

    const handleSave = async (e, carrier) => {
        e.preventDefault();
        setLoading(true);

        // Use the new price if provided; otherwise, fall back to the existing price
        const finalPrice = price.trim() === '' ? AllCarrier[carrier] : price;

        try {
            await dispatch(UpdatePriceDisService({ carrierName: carrier, newPrice: finalPrice,id })).unwrap();
                dispatch(getAllCarriersPriceByDisService(id))

        } catch (error) {
            console.error('Failed to update price:', error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <>

            <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
                <h3 className="font-medium w-[98%] mx-auto text-xl max-[768px]:text-center">Edit Price</h3>
            </div>

            <div className='grid grid-cols-4 justify-center mx-auto gap-3 w-11/12 mx-auto'>
            
            {Object.keys(AllCarrier).map((carrier) => (
                <div key={carrier} className='p-3' style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' }}>
                    <div className='bg-grey p-3'>
                        <h3 className='text-blue border-b bg-grey-400'>{carrier}</h3>
                    </div>
                    <div>
                        <label>User price</label>
                        <div className="relative inline-flex items-center">
    <span className="absolute left-3 text-gray-500">$</span>
    <input
        placeholder="User Price (in $)"
        value={currentCarrier === carrier ? price : AllCarrier[carrier]}
        onChange={(e) => {
            setCurrentCarrier(carrier);
            handlePriceChange(e);
        }}
        name={`${carrier}-price`}
        className="pl-8 border p-2 pb-2"
    />
</div>
                    </div>
                    <div>
                        <Button className='mt-1' onClick={(e) => handleSave(e, carrier)}>
                            {loading && currentCarrier === carrier ? <Spinner animation="border" /> : 'Save'}
                        </Button>
                    </div>
                </div>
            ))}
            </div>

        </>
    )
}

export default EditPriceDis