import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { BASE_URL } from "../auth/baseUrl";
import { handleError } from "../utils/handleError";
import { basicAuth } from "../auth/basicauth"; // Import basicAuth from the appropriate location
import { sucessToast } from "../toast/toast";

export const CustomerOrderTrackingService = createAsyncThunk(
  "CustomerOrderTrackingService",
  async (payload) => {
    console.log(payload,'sjsjss')
    try {
      const endDate = new Date(payload?.endDate);
      
      // Add 1 day to the endDate
      endDate.setDate(endDate.getDate() + 1);
      
      // Convert the updated Date object back to a string (in yyyy-mm-dd format)
      const updatedEndDate = endDate.toISOString().split('T')[0]
      let url = BASE_URL + `orderTrackingByCustomerId?customerId=${payload?.name}&startDate=${payload?.startDate}&endDate=${updatedEndDate}`;
      const config = {
        headers: {
          "Authorization": basicAuth, // Include basic authentication header
          'Content-Type': 'application/json' // Set content type to application/json
        }
      };
      const res = await axios.get(url, config); 
     sucessToast(res?.data)
      return res.data;
    } catch (error) {
      console.log(error)
      handleError(error); // Pass the entire error object to the handleError function

      throw error;
    }
  }
);
