import { createStore, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk'; // Importing thunk directly
import rootReducer from './reducer/combinereducer';

// Initializing State
const initialState = {};

const middleware = [thunk];

const store = createStore( rootReducer,initialState, applyMiddleware(...middleware));

export default store;
