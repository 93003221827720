import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { BASE_URL } from "../auth/baseUrl";
import { handleError } from "../utils/handleError";
import { basicAuth } from "../auth/basicauth"; // Import basicAuth from the appropriate location

export const UpdateUserService = createAsyncThunk(
  "UpdateUserService",
  async (payload) => {
    console.log(payload,'payload')
    const adminID=localStorage.getItem('adminId')
    const stausString=payload?.data?.status
    const bool=payload?.data?.status==='true'
    const paymentModel=payload?.data?.paymentModel==='true'


    const data={   
        //  "city":"newCity",
        // "state":"newState",
        // "location":"newLocation",
        // "paymentMode":'',
        "isPostPaidAccount":paymentModel,

      "user": {
          "name": payload?.data?.name,
          "email": payload?.data?.email,
          "password": payload?.data?.password,
          "phoneNumber": payload?.data?.Phone,
          "createdDate": null,
          "updatedDate": null,
        "acountStatus":bool,

      },
      "passAdminId":{
          "adminId":adminID
      }
  
  }
    try {
      let url = BASE_URL + `updateDistributerById/${payload?.id}`;
      const config = {
        headers: {
          "Authorization": basicAuth, // Include basic authentication header
          'Content-Type': 'application/json' // Set content type to application/json
        }
      };
      const res = await axios.put(url, data, config); // Pass the config object as the third parameter
      console.log(res)
      return res.data;
    } catch (error) {
      console.log(error)
      handleError(error); // Pass the entire error object to the handleError function

      throw error;
    }
  }
);
