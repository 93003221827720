import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { BASE_URL } from "../auth/baseUrl";
import { handleError } from "../utils/handleError";
import { basicAuth } from "../auth/basicauth"; // Import basicAuth from the appropriate location

export const AddNewClientService = createAsyncThunk(
  "AddNewClientService",
  async (payload) => {
    const adminID=localStorage.getItem('adminId')
    const newmodll=payload?.paymentModel=='true'
    const data={   
        //  "city":"newCity",
        // "state":"newState",
        // "location":"newLocation",
        "isPostPaidAccount":newmodll,
      "user": {
          "name": payload?.name,
          "email": payload?.email,
          "password": payload?.password,
          "phoneNumber": payload?.Phone,
          "createdDate": null,
          "updatedDate": null
      },
      "passAdminId":{
          "adminId":adminID
      }
  
  }
    try {
      let url = BASE_URL + 'addDistributer';
      const config = {
        headers: {
          "Authorization": basicAuth, // Include basic authentication header
          'Content-Type': 'application/json' // Set content type to application/json
        }
      };
      const res = await axios.post(url, data, config); // Pass the config object as the third parameter
      return res.data;
    } catch (error) {
      console.log(error)
      handleError(error); // Pass the entire error object to the handleError function
      throw error;
    }
  }
);
