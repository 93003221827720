import { createSlice } from "@reduxjs/toolkit";
import { sucessToast } from "../toast/toast";
import { VerifyOtpService } from "../service/verifyOtpService";
const initialState = {
  loading: false,
  error: null,
};

const VerifyOtpSlice = createSlice({
  name: "VerifyOtpSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(VerifyOtpService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(VerifyOtpService.fulfilled, (state, action) => {
      return { ...state,  loading: false };
    });
    builder.addCase(VerifyOtpService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default VerifyOtpSlice.reducer;

