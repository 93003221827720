import { createSlice } from "@reduxjs/toolkit";
import { sucessToast } from "../toast/toast";

import { DeleteOrderService } from "../service/deleteOrderService";
const initialState = {
  loading: false,
  error: null,
};

const DeleteOrderSlice = createSlice({
  name: "DeleteOrderSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(DeleteOrderService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(DeleteOrderService.fulfilled, (state, action) => {
    });
    builder.addCase(DeleteOrderService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default DeleteOrderSlice.reducer;

