import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { BASE_URL } from "../auth/baseUrl";
import { handleError } from "../utils/handleError";
import { basicAuth } from "../auth/basicauth"; // Import basicAuth from the appropriate location

import { sucessToast } from "../toast/toast";

export const UploadDisBulkUploadService = createAsyncThunk(
  "UploadDisBulkUploadService",
  async (payload) => {
    console.log(payload,'scjnkc')
    try {
        
        const formData=new FormData()
        formData.append('file',payload?.file)
        formData.append('orderId',payload?.orderId)


  
      let url = BASE_URL + `importDistributerSimCardList`;
      const config = {
        headers: {
          "Authorization": basicAuth, // Include basic authentication header
          "ngrok-skip-browser-warning" : "skip-browser-warning",
        }
      };
      const res = await axios.post(url,formData, config); // Pass the config object as the third parameter
        sucessToast(res.data)
      return res.data;
    } catch (error) {
      console.log(error)
      handleError(error); // Pass the entire error object to the handleError function
      throw error;
    }
  }
);
