import React, { useEffect, useState } from "react";
import { PhoneIcon, AddIcon, WarningIcon, ViewIcon } from '@chakra-ui/icons'
import view from '../assets/view.png'
import edit from '../assets/edit.png'
import exportpng from '../assets/export.png'
import { Button, Input, Spinner } from "@chakra-ui/react";

import { getAllOrderManagement } from "../service/getAllorderManagement";
import { useDispatch, useSelector } from "react-redux";
import { UpdateOrderStatus } from "../service/updateOrderStatusService";
import { UpdateDistributorService } from "../service/updateDistributorOrderStatusService";
import { exportToCSV } from "./exportCsv";
import { exportToexport } from "./exportCsv";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure
} from '@chakra-ui/react'
import { AddNewOrderSimNumber } from "../service/addOrderSimnumberService";
import { getAllCustomerOrderService } from "../service/newGetAllcustomerOrderService";
import { getOrderDatabyId } from "../service/getdataOrderByIdService";
import { DeleteOrderService } from "../service/deleteOrderService";
import { UploadFileService } from "../service/uploadFileService";
const CustomerOrderManagement = () => {
    const [orderId, setOrderId] = useState()
    const [size, setSize] = React.useState('2xl')

    const newLoading = useSelector((state) => state?.udpateDistributorOrderSlice?.loading)
    const loadingnew = useSelector((state) => state?.addNewSimOrderSlice?.loading)
    const myloading = useSelector((state) => state?.UploadFileSlice?.loading)


    const [quantitynew, setNewQuantity] = useState('')
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [searchValue, setInputvalue] = useState('')

    const { data } = useSelector((state) => state?.newGetAllcustomerOrderSlice)
    console.log(data)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllCustomerOrderService())
    }, [dispatch])

    const [filterData, setFilterData] = useState([])
    useEffect(() => {
        if (!searchValue) {
            setFilterData(data); // Reset filterData to original data when searchValue is empty
        } else {
            const newData = data.filter(item =>
                item.customerName && item.customerName.toLowerCase().includes(searchValue.toLowerCase())
            );
            setFilterData(newData); // Update filterData with filtered data
        }
    }, [searchValue, data]); // Include 'data' in dependencies array

    const handleOrerStatus = async (e) => {
        const newData = {
            orderId: orderId,
            quantitynew: quantitynew
        }
        await dispatch(UpdateDistributorService(newData)).unwrap()

        dispatch(getAllOrderManagement())


    }


    
    const [newDatadata, setData] = useState({
        transerPin: "",
        expiryDate: "",
        planNumber: "",
        accountNumber: '',
        ampm:""
    }
    )
    const hanldeInputChange = (e) => {
        const { value, name } = e.target
        setData({
            ...newDatadata,
            [name]: value
        })

    }

    const orderDetail = useSelector((state) => state?.getOrderDataByIdSlice?.data)






    const handleClick = async () => {
        const temp = {
            orderId: orderId,
            newDatadata: newDatadata
        };

        try {
            await dispatch(AddNewOrderSimNumber(temp)).unwrap();
            dispatch(getAllCustomerOrderService())

            dispatch(getAllOrderManagement())

            setData({
                transerPin: "",
                expiryDate: "",
                planNumber: "",
                accountNumber: ''
            });
        } catch (error) {
            console.error("Failed to add new order:", error);
        }
    };





    const copyToClipboard = () => {
        // Define headers
        const headers = ['Name', 'Email', 'Order Qantity'];

        // Prepare the text data
        let textData = '';

        // Add headers
        textData += headers.join('\t') + '\n'; // Use tab (\t) for Copying options, newline (\n) for rows

        // Add rows from filterData
        filterData.forEach(item => {
            // Ensure each item has all the required fields
            const row = [
                item?.distributerName || '',       // Ensure default value if missing
                item?.customerName || '',  // Ensure default value if missing
                item?.orderedQuantity || '',   // Ensure default value if missing
            ].join('\t'); // Use tab (\t) for Copying options
            textData += row + '\n'; // Add a newline for each row
        });
        navigator.clipboard.writeText(textData).then(
            () => alert('Table data copied to clipboard!'),
            (err) => console.error('Failed to copy table data: ', err)
        );
    };
    
    
    const copyorderDetail = () => {
        // Define headers
        const headers = ['Phone No.', 'Account No.', 'Transfer Pin', 'Expiry Date', 'AM/PM ?'];
    
        // Prepare the text data (initialized with the headers)
        let textData = headers.join('\t') + '\n'; // Use tab (\t) for separating columns, newline (\n) for the row
    
        // Add rows from orderDetail
        orderDetail.forEach(item => {
            // Ensure each item has all the required fields, fallback to empty string if missing
            const row = [
                item?.phoneNumber || '',       // Default to empty if missing
                item?.accountNumber || '',     // Default to empty if missing
                item?.pin || '',               // Default to empty if missing
                item?.expiryDate || '',        // Default to empty if missing
                item?.timePeriod || ''         // Default to empty if missing
            ].join('\t' + '       '); // Join each field with a tab and a space between them
      
            
            // Add the row to the textData (with newline after each row)
            textData += row + '\n';
        });
    
        // Copy the table data to clipboard
        navigator.clipboard.writeText(textData).then(
            () => alert('Table data copied to clipboard!'),
            (err) => console.error('Failed to copy table data: ', err)
        );
    };
    
    const headers = ['Phone number', 'Account Number', 'Transfer Pin', 'Expiry Date', 'AM/PM'];

    const newData = [
        headers,  // Add the headers first
        ...(Array.isArray(orderDetail) ? orderDetail : []).map(item => {
            // Ensure each item has all the required fields, fallback to empty string if missing
            const row = [
                item?.phoneNumber || '',       // Default to empty if missing
                item?.accountNumber || '',     // Default to empty if missing
                item?.pin || '',               // Default to empty if missing
                item?.expiryDate || '',        // Default to empty if missing
                item?.timePeriod || ''         // Default to empty if missing
            ];
            return row; // Return the row for each item in the array
        })
    ];
    
    console.log(newData,'newDatanewDatanewData')

    const [isOpen2, setOpen] = useState(false)

    const handleViewDetails = (id) => {
        console.log(id, 'kxfhjojlhkgfghjklkjhhg')
        dispatch(getOrderDatabyId(id))

        setOpen(true)
    }

    const handleClose = () => setOpen(false);
    const convertToCST = (dateStr) => {
        const date = new Date(dateStr);
        const offset = -6; // CST offset from UTC

        // Adjust for daylight saving time if necessary
        const utcOffset = date.getTimezoneOffset() / 60;
        const cstDate = new Date(date.getTime() + (offset - utcOffset) * 60 * 60 * 1000);

        return cstDate.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            timeZoneName: 'short',
        });
    };




    const handleDelte = async (id) => {
        try {
            const confirm = window.confirm('Are you sure you want to delete this Order History ?');
            if (confirm) {
                await dispatch(DeleteOrderService(id))
                dispatch(getAllCustomerOrderService())
            }
        } catch (e) {
            console.log(e)
        }

    }


    const [file, setFile] = useState('')

    const handleBulkUpload = async () => {
        console.log('Uploading file:', file);
        try {
            const newData = {
                file: file,
                orderId: orderId
            }
            await dispatch(UploadFileService(newData)).unwrap()
            dispatch(getAllCustomerOrderService())
            setFile('')
        } catch (err) {
            console.log(err)
        }

    }


    function formatTimestampToUS(timestamp) {
        if(timestamp==null){
            return ''
        }
        const date = new Date(timestamp);

        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };

        return date.toLocaleString('en-US', options);
    }
    return (
        <>
            <style>
                {

                    `
                  th{
                text-align:center;
                border-right:1px solid white;
                background:#FBB04B;
                color:white;
                }
                  td{
                text-align:center;
                }

                
                `
                }
            </style>



            <Modal onClose={handleClose} size={size} isOpen={isOpen2}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Your Order Details</ModalHeader>
                    <div className="w-[98%]  justify-center mx-auto mt-4 flex gap-2 items-center mb-4 max-[768px]:justify-between max-[768px]:w-[94%]">
                        <div>
                            <p style={{ color: '#8A8D56' }}>Copying options</p>
                        </div>
                        <div>
                            <Button onClick={() => exportToexport(newData)}>
                                <img src={exportpng} />
                                Excel
                            </Button>
                        </div>
                        <div>
                            <Button onClick={() => exportToCSV(newData)}>
                                <img src={exportpng} />
                                CSV
                            </Button>
                        </div>
                        <div>
                            <Button onClick={copyorderDetail}>
                                <img src={exportpng} />
                                Copy
                            </Button>
                        </div>
                    </div>
                    <ModalCloseButton />
                    <ModalBody>

                        <table class="w-full text-sm text-left text-gray-500">
                            <thead class="text-xs text-gray-700 uppercase bg-gray-300 sticky top-0 z-10">
                                <tr>
                                    <th scope="col" class="px-6 py-3">Phone Number</th>
                                    <th scope="col" class="px-6 py-3">Account Number</th>
                                    <th scope="col" class="px-6 py-3">Transfer Pin</th>
                                    <th scope="col" class="px-6 py-3">Expiry date and time</th>
                                    <th scope="col" class="px-6 py-3">AM / PM ?</th>



                                </tr>
                            </thead>
                            <tbody class="bg-white">
                                {

                                    Array.isArray(orderDetail) && orderDetail.map((item, index) => {
                                        return (
                                            <>

                                                <tr class="border-b">
                                                    <td class="px-6 text-justify py-4 font-medium whitespace-nowrap">
                                                        {item?.phoneNumber}
                                                    </td>
                                                    <td class="px-6 py-4 grid grid-cols-2  font-medium gap-2 mt-[22px]">
                                                        {item?.accountNumber}

                                                    </td>
                                                    <td class="px-6 py-4  font-medium">{item?.pin}</td>
                                                    <td class="px-0 py-4  font-medium text-[10px]">

                                                        {item?.expiryDate}

                                                    </td>
                                                    <td class="px-0 py-4  font-medium text-[10px]">

{item?.timePeriod}

</td>



                                                </tr>
                                            </>
                                        )
                                    })
                                }



                            </tbody>
                        </table>
                    </ModalBody>

                </ModalContent>
            </Modal>



            <Modal onClose={onClose} size={size} isOpen={isOpen}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Make A Order</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <div className="grid grid-cols-2 gap-3">
                            <div>
                                <Input name="planNumber" value={newDatadata?.planNumber} placeholder="Phone number" onChange={hanldeInputChange}></Input>
                            </div>
                            <div>
                                <Input name="accountNumber" value={newDatadata?.accountNumber} placeholder="Account Number" onChange={hanldeInputChange}></Input>
                            </div>
                            <div>
                                <Input name="transerPin" value={newDatadata?.transerPin} placeholder="Transfer Pin" onChange={hanldeInputChange}></Input>
                            </div>
                            <div className=" items-center flex">
                                <label className="text-[13px]">
                                    Expiry date and time
                                </label>
                                <Input name="expiryDate" className="" style={{ width: '70%' }} value={newDatadata?.expiryDate} placeholder="" type="datetime-local" onChange={hanldeInputChange}></Input>
                            </div>
                            <div className=" items-center flex">
                                <label className="text-[13px]">
                                  Time Period
                                </label>
                                <Input name="ampm" className="" style={{ width: '70%' }}  value={newDatadata?.ampm} placeholder="AM/PM ?" type="text" onChange={hanldeInputChange}></Input>
                            </div>
                        </div>
                        <div className="flex justify-end mt-2">
                            <Button onClick={handleClick}>
                                {
                                    loadingnew ? (
                                        <>
                                            <Spinner />
                                        </>
                                    ) : 'Complete Order'
                                }
                            </Button>
                        </div>
                    </ModalBody>
                    <div className="" style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' }}>
                        <div className="p-3 leading-[34px]" style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <label className="font-[700] ">Bulk Upload</label>
                            <input type="file" onChange={(e) => setFile(e.target.files[0])} />
                            <div className="">
                                <Button onClick={handleBulkUpload}>
                                    {
                                        myloading ? (
                                            <>
                                                <Spinner />
                                            </>
                                        ) : 'Save'
                                    }
                                </Button>
                            </div>
                        </div>

                    </div>


                </ModalContent>
            </Modal>
            <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
                <h3 className="font-medium w-[98%] mx-auto text-xl max-[768px]:text-center">Customer Order Management</h3>
            </div>

            <div className="mt-5 w-[98%] mx-auto " style={{ background: 'rgba(243, 251, 254, 1)' }}>
                <div className="flex justify-between items-center w-[98%] p-2 mx-auto">
                    <div className="">
                        <h1 className="font-[700] " style={{ color: '#8A8D56' }}> Your Complete Order List</h1>
                    </div>
                    <div className="">
                    </div>
                </div>
            </div>


            <div className="">
                <div className="w-[98%]  max-[768px]:flex-wrap  mx-auto mt-4 flex gap-2 items-center justify-between mb-4 max-[768px]:justify-center max-[768px]:w-[94%] ">
                    <div className="w-[98%] mx-auto mt-4 flex gap-2 items-center mb-4 max-[768px]:justify-between max-[768px]:w-[94%]">
                        <div>
                            <p style={{ color: '#8A8D56' }}>Copying options</p>
                        </div>
                        <div>
                            <Button onClick={() => exportToexport(filterData)}>
                                <img src={exportpng} />
                                Excel
                            </Button>
                        </div>
                        <div>
                            <Button onClick={() => exportToCSV(filterData)}>
                                <img src={exportpng} />
                                CSV
                            </Button>
                        </div>
                        <div>
                            <Button onClick={copyToClipboard}>
                                <img src={exportpng} />
                                Copy
                            </Button>
                        </div>
                    </div>
                    <div>
                        <div class='max-w-md mx-auto max-[768px]:flex '>
                            <div style={{ border: '1px solid #8A8D56' }} class="relative flex items-center w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden">
                                <div class="grid place-items-center h-full w-12 text-gray-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                    </svg>
                                </div>

                                <input
                                    onChange={(e) => setInputvalue(e.target.value)}
                                    class="peer h-full w-full outline-none text-sm text-gray-700 pr-2 "
                                    type="text"
                                    id="search"
                                    placeholder="Search something.." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <body class="w-full mx-auto bg-gray-100">
                <div class="w-full mx-auto mt-5 overflow-x-auto">
                    <div class="overflow-y-auto max-h-[50vh]">
                        <table class="w-full text-sm text-left text-gray-500">
                            <thead class="text-xs text-gray-700 uppercase bg-gray-300 sticky top-0 z-10">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-justify">Ref</th>
                                    <th scope="col" class="px-6 py-3">Carier</th>
                                    <th scope="col" class="px-6 py-3">Affiliated By</th>

                                    <th scope="col" class="px-[80px] py-3">Quantity</th>
                                    <th scope="col" class="px-[80px] py-3">Price Difference</th>

                                    <th scope="col" class="px-6 py-3">Area Code</th>
                                    <th scope="col" class="px-6 py-3">Cost </th>
                                    <th scope="col" class="px-6 py-3">Status</th>
                                    <th scope="col" class="px-6 py-3">Order Note</th>
                                    <th scope="col" class="px-6 py-3">Time Order Made</th>
                                    <th scope="col" class="px-6 py-3">Time Order Completed </th>
                                  
                                    <th scope="col" class="px-6 py-3">Action</th>
                                    <th scope="col" class="px-6 py-3">View Numbers</th>
                                    <th scope="col" class="px-6 py-3">Delete</th>


                                </tr>
                            </thead>
                            <tbody class="bg-white">
                                {

                                    Array.isArray(filterData) && [...filterData].reverse().map((item, index) => {
                                        return (
                                            <>

                                                <tr class="border-b">
                                                    <td class="px-6 text-justify py-4 font-medium text-gray-900 whitespace-nowrap">
                                                        <p>
                                                            Id: {index + 1}
                                                        </p>
                                                        <p>
                                                            Name: {item?.customerName}
                                                        </p>
                                                    </td>
                                                    <td class="px-6 py-4">{item?.carrier}</td>
                                                    <td class="px-6 py-4">{item?.adminName||item?.distributerName}</td>

                                                    <td class="px-2 py-4 flex gap-2 items-center mt-[22px]">
                                                        <Button className="bg-[green-500]">
                                                            {item?.availableQuantity}
                                                        </Button>
                                                        Ready  out of
                                                        <Button className="ml-1">
                                                            {item?.orderedQuantity}
                                                        </Button>
                                                    </td>
                                                    <td class="px-6 py-4 text-green-500 font-medium">{(isNaN(Number(item?.priceDifference)) ? 0 : Number(item?.priceDifference)).toFixed(2)}

                                                    </td>

                                                    <td class="px-6 py-4 text-green-500 font-medium">{item?.areaCode}</td>
                                                    <td class="px-6 py-4  font-medium">${item?.cost}</td>
                                                    <td class={`px-6 py-4 ${item.status == 'Delivered' ? 'text-green-500' : 'text-red-500'} font-medium`} >
                                                        {item?.status === 'InActive' ? 'Pending' : item?.status}
                                                    </td>
                                                    <td class="px-6 py-4  font-medium">
                                                        {item?.statusDescription}
                                                    </td>
                                                    <td class="px-6 py-4 font-medium">{formatTimestampToUS(item?.createdDate)}</td>
                                                    <td class="px-6 py-4 font-medium">{formatTimestampToUS(item?.updatedDate)}</td>
                                                  
                                                    <td class="px-6 py-4  font-medium">

                                                        <button onClick={() => { onOpen(); setOrderId(item?.orderId); }} style={{ background: '#8A8D56' }} className="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                            Complete Order
                                                        </button>

                                                    </td>

                                                    <td class="px-6 py-4  font-medium">

                                                        <button style={{ background: '' }} onClick={() => handleViewDetails(item?.orderId)} className="">
                                                            View Numbers
                                                        </button>

                                                    </td>

                                                    <td class="px-6 py-4  font-medium">

                                                        <Button style={{ background: '' }} onClick={() => handleDelte(item?.orderId)} className="">
                                                            Delete
                                                        </Button>

                                                    </td>

                                                </tr>
                                            </>
                                        )
                                    })
                                }



                            </tbody>
                        </table>
                    </div>
                </div>
            </body>

        </>
    )
}

export default CustomerOrderManagement