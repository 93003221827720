import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { BASE_URL } from "../auth/baseUrl";
import { handleError } from "../utils/handleError";
import { basicAuth } from "../auth/basicauth"; // Make sure the path is correct

export const VerifyOtpService = createAsyncThunk(
  "VerifyOtpService",
  async (payload, { rejectWithValue }) => {

    console.log(payload,'hi')
    const validateInput = (input) => (input && input.trim() !== "") ? input : null;

const newEmail = validateInput(payload?.email);  // Validate the email
const newPassword = validateInput(payload?.password);  // Validate the password

   // Start constructing the URL with required parameters (email and otp are mandatory)
let url = `${BASE_URL}resetAdminPassword?email=${payload.oldEmail}&otp=${payload.otp}`;

// Only append newEmail if it's valid (not null or empty)
if (newEmail) {
  url += `&newEmail=${(newEmail)}`;
}

// Only append newPassword if it's valid (not null or empty)
if (newPassword) {
  url += `&newPassword=${(newPassword)}`;
}



    try {
      const unewrl = `${url}`;
      const config = {
        headers: {
          "Authorization": basicAuth, // Include basic authentication header
          'Content-Type': 'application/json',
          "ngrok-skip-browser-warning" : "skip-browser-warning",
        
        }

      };
      const res = await axios.put(unewrl, {}, config); // Pass the config object as the third parameter
      console.log(res);
      return res.data;
    } catch (error) {
      console.error(error);
      handleError(error); // Pass the entire error object to the handleError function

      return rejectWithValue(error.response.data); // Handle error with rejectWithValue
    }
  }
);
