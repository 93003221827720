import { createSlice } from "@reduxjs/toolkit";
import { sucessToast } from "../toast/toast";

import { DeleteCarrierByIdService } from "../service/deleteCarrierByIdService";
const initialState = {
  loading: false,
  error: null,
};

const DeleteCarrierByIdSlice = createSlice({
  name: "DeleteCarrierByIdSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(DeleteCarrierByIdService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(DeleteCarrierByIdService.fulfilled, (state, action) => {
      return { ...state,  loading: false };
    });
    builder.addCase(DeleteCarrierByIdService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default DeleteCarrierByIdSlice.reducer;

