import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { BASE_URL } from "../auth/baseUrl";
import { handleError } from "../utils/handleError";
import { basicAuth } from "../auth/basicauth"; // Import basicAuth from the appropriate location

export const editCustomerService = createAsyncThunk(
  "editCustomerService",
  async (payload) => {
    console.log(payload)
    const adminID=localStorage.getItem('adminId')
    const statusString = payload?.data.status;
const statusBoolean = statusString === 'true';
const newalue=payload?.data?.paymentMode==='true'
    const data={    
       "isPostPaidAccount":newalue,
      "user": {
          "name": payload?.data.name,
          "email": payload?.data.email,
          "password": payload?.data.password,
          "phoneNumber": payload?.data.number,
          "createdDate": null,
          "updatedDate": null,
          "acountStatus":statusBoolean,
      },
      "passAdminId":{
          "adminId":adminID
      }
  
  }
    try {
      let url = BASE_URL + `updateCustomerById/${payload?.id}`;
      const config = {
        headers: {
          "Authorization": basicAuth, // Include basic authentication header
          'Content-Type': 'application/json' // Set content type to application/json
        }
      };
      const res = await axios.put(url, data, config); // Pass the config object as the third parameter
      console.log(res)
      return res.data;
    } catch (error) {
      console.log(error)
      handleError(error); // Pass the entire error object to the handleError function

      throw error;
    }
  }
);
