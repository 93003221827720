import React, { useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Spinner,
  FormErrorMessage,
  Select,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { AddCustomerService } from '../service/addCustomerService';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AddCustomerPanel = () => {
  const { loading } = useSelector((state) => state?.addCustomerSlice);
  const [data, setData] = useState({
    name: '',
    email: '',
    password: '',
    ConfirmPassword: '',
    number: '',
    paymentMode: '',
    balance: '',
    status: '',
  });
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    number: '',
    paymentMode: '',
    balance: '',
    status: '',
    password: ""
  });
  const validateForm = () => {
    const errors = {};
    if (!data.name) {
      errors.name = 'Customer is required';
    }
    if (!data.email) {
      errors.email = 'Customer Email is required';
    }
    if (!data.password) {
      errors.password = 'Customer Password is required';
    }


    if (!data.number) {
      errors.number = 'Customer Number is required';
    }
    if (!data.paymentMode) {
      errors.paymentMode = 'Customer Payment Mode is required';
    }
    return errors;
  };


  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });

  };



  const handleClick = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    try {
      // Validate the form and set errors if any
      const errors = validateForm();
      setErrors(errors);

      // Check if there are no validation errors
      if (Object.keys(errors).length === 0) {
        // Dispatch the action and wait for it to complete
        const response = await dispatch(AddCustomerService(data)).unwrap();
        if (response == "Customer added successfully") {
          navigate('/numbersystem/admin/CustomerPanel')
        }
        // Handle successful response

      } else {
        // Handle form validation errors
        console.log('Form errors:', errors);
      }
    } catch (err) {
      // Handle any errors that occur during dispatch or other operations
      console.error('Error occurred:', err);
    }
  };
  return (
    <>
      <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
        <h3 className="font-medium w-[98%] mx-auto text-xl max-[768px]:text-center">
          Customer Panel
        </h3>
      </div>
      <div className="flex mx-auto justify-center items-center h-full mt-4">
        <div
          className="rounded-[5px] w-[60%] max-[768px]:w-[90%]"
          style={{
            boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
          }}
        >
          <div className="px-4 py-3 text-xl border-b-2" style={{ background: 'rgba(243, 251, 254, 1)' }}>
            <h3 className="text-[#8A8D56] font-medium">Add Customer</h3>
          </div>
          <div className="p-4">
            <div>
              <FormControl isInvalid={!!errors.name}>
                <FormLabel margin={'10px'} color={'grey'}>
                  Customer Name
                </FormLabel>
                <Input
                  value={data.name}
                  onChange={handleChange}
                  placeholder="Name"
                  name="name"
                />
                <FormErrorMessage>{errors.name}</FormErrorMessage>
              </FormControl>
            </div>
            <div>
              <FormControl isInvalid={!!errors.email}>
                <FormLabel margin={'10px'} color={'grey'}>
                  Customer Email
                </FormLabel>
                <Input
                  type="email"
                  value={data.email}
                  onChange={handleChange}
                  placeholder="Email"
                  name="email"
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>
            </div>
            <div>
              <FormControl isInvalid={!!errors.password}>
                <FormLabel margin={'10px'} color={'grey'}>
                  Customer Password
                </FormLabel>
                <Input
                  value={data.password}
                  placeholder="Password"
                  onChange={handleChange}
                  name="password"
                />
                <FormErrorMessage>{errors.password}</FormErrorMessage>
              </FormControl>
            </div>
            <div>
              <FormControl isInvalid={!!errors.number}>
                <FormLabel margin={'10px'} color={'grey'}>
                  Customer  Phone Number
                </FormLabel>
                <Input
                  value={data.number}
                  placeholder="Phone Number"
                  onChange={handleChange}
                  name="number"
                />
                <FormErrorMessage>{errors.number}</FormErrorMessage>
              </FormControl>
            </div>
            <div>
              <FormControl isInvalid={!!errors.paymentMode}>
                <FormLabel margin={'10px'} color={'grey'}>
                  Customer Type
                </FormLabel>
                <Select
                  value={data?.paymentMode}
                  onChange={handleChange}
                  name="paymentMode"
                >
                  <option value="">
                    Select
                  </option>
                  <option value="true">
                    Postpaid
                  </option>
                  <option value="false">
                    Prepaid
                  </option>
                </Select>
                <FormErrorMessage>{errors.paymentMode}</FormErrorMessage>
              </FormControl>
            </div>
            {/* <div>
              <FormControl >
                <FormLabel margin={'10px'} color={'grey'}>
                  Customers Payment Amount (in $)
                </FormLabel>
                <Input
                  value={data.balance}
                  placeholder="Customers Payment Amount "
                  onChange={handleChange}
                  name="balance"
                />
              </FormControl>
            </div> */}
            <div>

            </div>
         
            <button
              onClick={handleClick}
              className="bg-[#8A8D56] hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-3"
            >
              {loading ? <Spinner /> : <><AddIcon /> Save Customer</>}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCustomerPanel;
