import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { BASE_URL } from "../auth/baseUrl";
import { handleError } from "../utils/handleError";
import { basicAuth } from "../auth/basicauth"; // Make sure the path is correct

export const LoginService = createAsyncThunk(
  "LoginService",
  async (payload, { rejectWithValue }) => {
    const data = {
      email: payload.email,
      password: payload.password
    };

    try {
      const url = `${BASE_URL}adminLogin`;
      const config = {
        headers: {
          "Authorization": basicAuth, // Include basic authentication header
          'Content-Type': 'application/json',
          "ngrok-skip-browser-warning" : "skip-browser-warning",
        
        }

      };
      const res = await axios.post(url, data, config); // Pass the config object as the third parameter
      console.log(res);
      return res.data;
    } catch (error) {
      console.error(error);
      handleError(error); // Pass the entire error object to the handleError function

      return rejectWithValue(error.response.data); // Handle error with rejectWithValue
    }
  }
);
