import { createSlice } from "@reduxjs/toolkit";
import { sucessToast } from "../toast/toast";

import { getAllCarriersPriceByDisService } from "../service/getAllcarrirerPricesDisId";
const initialState = {
  data: [],
  loading: false,
  error: null,
};

const getAllcarrierBydisIdSlice = createSlice({
  name: "getAllcarrierBydisIdSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllCarriersPriceByDisService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(getAllCarriersPriceByDisService.fulfilled, (state, action) => {
      // sucessToast("Broker Data Get Sucessfully");
      return { ...state,  loading: false,data:action.payload };
    });
    builder.addCase(getAllCarriersPriceByDisService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default getAllcarrierBydisIdSlice.reducer;

