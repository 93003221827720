


import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { BASE_URL } from "../auth/baseUrl";
import { handleError } from "../utils/handleError";
import { basicAuth } from "../auth/basicauth"; // Import basicAuth from the appropriate location

export const AddCreditsForDistributorService = createAsyncThunk(
  "AddCreditsForDistributorService",
  async (payload) => {
    try {
      const {refund}=payload
      const {data}=payload
      console.log(payload.amount,'payload')
      console.log(payload,'dmsvsvskj')
      const adminId = localStorage.getItem('adminId');

      const newdata = {
        "description": data.desc,
        "amount": data.amount,
        "createdDate": null,
        "status": refund,

        "updatedDate": null,
        "passDistributer": {
          "distributerId": data?.clientName
        }
      };

      let url = BASE_URL + 'addManageCreditByDistributerId';
      const config = {
        headers: {
          "Authorization": basicAuth,
          'Content-Type': 'application/json'
        }
      };

      const res = await axios.post(url, newdata, config);
      console.log(res.data);
      return res.data;
    } catch (error) {
      console.error("Error in AddCreditsForCustomerService:", error);
      handleError(error);
      throw error;
    }
  }
);
