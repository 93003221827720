import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import Login from './auth/login';
import Header from './header/header';
import Dashboard from './dashboard/dashboard';
import OrderManagement from './dashboard/orderManagement';
import CustomerPanel from './dashboard/customerpanel';
import UserManagement from './dashboard/usermanagment';
import './App.css';
import OrderHistory from './dashboard/OrderHistory';
import OrderTracking from './dashboard/ordertracking';
import CreditManagement from './dashboard/creditmanagement';
import AddCarrier from './dashboard/addCarrier';
import AddCustomerPanel from './dashboard/addCustomer';
import AddNewClient from './dashboard/addNewClient';
import EditCarrier from './Editform/editCarrier';
import EditCustomer from './Editform/editcustomer';
import EditClient from './Editform/editClient';
import UpdateCarrier from './dashboard/updateCarrier';
import UpdateCustomer from './dashboard/updateCustoomer';
import CustomerOrderManagement from './dashboard/customerOrderHistory';





import AboutUs from './otherpages/aboutus';
import FaqSection from './otherpages/faq';
import TermsCondition from './otherpages/termscondition';
import PrivacyPolicy from './otherpages/privacyPolicy';
import ReturnExchange from './otherpages/returnExchange';
import { sucessToast } from './toast/toast';
import { emitter } from './dashboard/emit';
import Contact from './dashboard/contact';
import EditPriceDis from './Editform/editprice';
import EditCustomerPrice from './Editform/editcustomerPrice';
import DistributorOrderTracking from './dashboard/disorderTracking';
import ResetOtp from './auth/resetpassword';
import VerifyOTP from './auth/verifyOTP.js';


function App() {
  const tokenkey = localStorage.getItem('adminId');
  const navigate = useNavigate();
  const [login, setLogin] = useState(false);

  useEffect(() => {
    if (tokenkey) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }, []);

  // Function to handle login
  const handleLogin = () => {
    // Perform login logic, e.g., validate credentials
    // For demo purposes, directly setting login state to true
    setLogin(true);
    navigate('/numbersystem/admin'); // Redirect to admin page after login
  };

  useEffect(() => {
    emitter.on("logout", () => {
      setLogin(false)
      navigate('/')
      // errorToast('')
      // navigate('/')
    });
  }, []);
  

  return (
    <>
      {login && <Header />}
      <Routes>
        {/* Route for Login */}
        <Route path="/numbersystem/ResetOtp" element={<ResetOtp />} />
        <Route path="/numbersystem/verifyOTP" element={<VerifyOTP />} />


        {!login && <Route path="/" element={<Login onLogin={handleLogin} />} />}
        {/* Routes for authenticated pages */}
        {login && (
          <>
            <Route path="/numbersystem/admin" element={<Dashboard />} />
            <Route path="/numbersystem/admin/Inventory" element={<OrderHistory />} />
            <Route path="/numbersystem/admin/CustomerPanel" element={<CustomerPanel />} />
            <Route path="/numbersystem/admin/userManagement" element={<UserManagement />} />
            <Route path="/numbersystem/admin/ordermanagement" element={<OrderManagement />} />
            <Route path="/numbersystem/admin/OrderTracking" element={<OrderTracking />} />
            <Route path="/numbersystem/admin/creditmanagement" element={<CreditManagement />} />
            <Route path="/numbersystem/admin/addCarrier" element={<AddCarrier />} />
            <Route path="/numbersystem/admin/AddCustomerPanel" element={<AddCustomerPanel />} />
            <Route path="/numbersystem/admin/AddNewClient" element={<AddNewClient />} />
            <Route path="/numbersystem/admin/editcarrier" element={<EditCarrier />} />
            <Route path="/numbersystem/admin/editCustomer" element={<EditCustomer />} />
            <Route path="/numbersystem/EditClient/:id" element={<EditClient />} />
            <Route path="/numbersystem/carrier/:id" element={<UpdateCarrier />} />
            <Route path="/numbersystem/customer/:id" element={<UpdateCustomer />} />
            <Route path="/numbersystem/admin/CustomerOrderHistory" element={<CustomerOrderManagement />} />
            <Route path="/numbersystem/editPrice/:id" element={<EditPriceDis />} />
            <Route path="/numbersystem/customerprice/:id" element={<EditCustomerPrice />} />
            <Route path="/numbersystem/admin/DistributorOrderTracking" element={<DistributorOrderTracking />} />
            <Route path="/numbersystem/admin/contact" element={<Contact />} />
            <Route path="/numbersystem/admin/AboutUs" element={<AboutUs />} />
            <Route path="/numbersystem/admin/Faq" element={<FaqSection />} />
            <Route path="/numbersystem/admin/termscondition" element={<TermsCondition />} />
            <Route path="/numbersystem/admin/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/numbersystem/admin/ReturnExchange" element={<ReturnExchange />} />

          </>
        )}
        {/* Catch-all route to redirect to login if no other route matches */}
      </Routes>
    </>
  );
}

export default App;
