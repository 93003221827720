import { createSlice } from "@reduxjs/toolkit";
import { sucessToast } from "../toast/toast";
import { getAllOrderTrackingService } from "../service/getAllorderTrackingService";
const initialState = {

  data: [],
  loading: false,
  error: null,
};

const getAllOrderTrackingsliice = createSlice({
  name: "getAllOrderTrackingsliice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllOrderTrackingService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(getAllOrderTrackingService.fulfilled, (state, action) => {
      // sucessToast("Broker Data Get Sucessfully");
      return { ...state,  loading: false,data:action.payload };
    });
    builder.addCase(getAllOrderTrackingService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default getAllOrderTrackingsliice.reducer;

