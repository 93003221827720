export const formatDate=(inputString)=> {
    // Parse the input string to a Date object
    const date = new Date(inputString);

    // Define an array of month names
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Format the date as "DD Month YYYY"
    return `${day} ${month} ${year}`;
}
