import react, { useState } from 'react'
import { FormControl, FormErrorMessage, FormLabel, Input, Select } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { AddNewClientService } from '../service/addNewClientService'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
const AddNewClient = () => {
    const { loading } = useSelector((state) => state?.addNewClientSlice)
    const [data, setData] = useState({
        name: "",
        email: "",
        password: "",
        paymentModel: "",
        status: "",
        Phone: "",
        paymentMode: ""

    })

    const [error, setError] = useState({
        name: "",
        email: "",
        password: "",
        paymentModel: "",
        Phone: ""
    })

    const validation = () => {
        const error = {}

        if (!data.name) {
            error.name = 'Name is required';
        }
        if (!data.email) {
            error.email = "Email is Required"
        }
        if (!data.password) {
            error.password = "password is Required"
        }
        if (!data.paymentModel) {
            error.paymentModel = "paymentModel is Required"
        }
        if (!data.Phone) {
            error.Phone = "Phone is Required"
        }
        return error
    }


    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })

    }
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleClick = async (e) => {
        try {
            const errors = validation()
            setError(errors)
            if (Object.keys(error).length === 0) {
                e.preventDefault()
                await dispatch(AddNewClientService(data)).unwrap()
                navigate('/numbersystem/admin/userManagement')
            }
            else {
                console.log('error')
            }
        }
        catch (err) {
            console.log(err)
        }



    }
    return (
        <> <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
            <h3 className="font-medium w-[98%] mx-auto text-xl max-[768px]:text-center">Add New Distributer</h3>
        </div>
            <div className='flex mx-auto justify-center items-center h-full mt-4'>
                <div className='rounded-[5px] w-[60%] max-[768px]:w-[90%]' style={{ boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px' }}>
                    <div className='px-4 py-3 text-xl border-b-2' style={{ background: 'rgba(243, 251, 254, 1)' }}>


                        <h3 className='text-[#8A8D56] font-medium'>Distributer</h3>
                    </div>
                    <div className='p-4'>
                        <div className=''>
                            <FormControl isInvalid={!!error.name}>
                                <FormLabel margin={'10px'} color={'grey'}>Distributer Name</FormLabel>
                                <Input onChange={handleChange} placeholder='Name' name='name' value={data?.name}></Input>
                                <FormErrorMessage>{error.name}</FormErrorMessage>
                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl isInvalid={!!error.email}>
                                <FormLabel margin={'10px'} color={'grey'}>Distributer Email</FormLabel>
                                <Input onChange={handleChange} placeholder='Email' name='email' value={data?.email}></Input>
                                <FormErrorMessage>{error.name}</FormErrorMessage>

                            </FormControl>

                        </div> <div className=''>
                            <FormControl isInvalid={!!error.Phone} >
                                <FormLabel margin={'10px'} color={'grey'}>Distributer Phone Number</FormLabel>
                                <Input onChange={handleChange} placeholder='Phone Number' name='Phone' type='number' value={data?.Phone}></Input>
                                <FormErrorMessage>{error.Phone}</FormErrorMessage>

                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl isInvalid={!!error.password} >

                                <FormLabel margin={'10px'} color={'grey'}>Distributer Password</FormLabel>
                                <Input onChange={handleChange} placeholder='Password' name='password' value={data?.password}></Input>
                                <FormErrorMessage>{error.password}</FormErrorMessage>

                            </FormControl>
                        </div>
                        <div className=''>
                            {/* <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Distributer Confirm Password</FormLabel>
                                <Input placeholder=' Confirm Password'></Input>
                            </FormControl> */}
                        </div>
                        <div className=''>
                            <FormControl isInvalid={!!error.paymentModel}>
                                <FormLabel margin={'10px'} color={'grey'}>Distributer Type</FormLabel>
                                <Select onChange={handleChange} placeholder=' Payment Mode' name='paymentModel' value={data?.paymentModel}>
                                    <option value={'true'}>Postpaid</option>
                                    <option value={'false'}>Prepaid</option>
                                </Select>
                            </FormControl>
                            <FormErrorMessage>{error?.paymentModel}</FormErrorMessage>

                        </div>

                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Distributer Status</FormLabel>
                                <Select onChange={handleChange} placeholder='Status' name='status' value={data?.status}>
                                    <option value={'active'}>Active</option>
                                    <option value={'inactive'}>Inactive</option>


                                </Select>

                            </FormControl>

                        </div>
                        <button onClick={handleClick} class="bg-[#8A8D56] hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-3">
                            <AddIcon />  Save Distributer
                        </button>
                        <div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AddNewClient