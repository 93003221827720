import { createSlice } from "@reduxjs/toolkit";
import { sucessToast } from "../toast/toast";

import { AddNewClientService } from "../service/addNewClientService";
const initialState = {
  loading: false,
  error: null,
};

const AddNewClientSlice = createSlice({
  name: "AddNewClientSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(AddNewClientService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(AddNewClientService.fulfilled, (state, action) => {
      sucessToast("Distributor  Added Sucessfully");
      return { ...state,  loading: false };
    });
    builder.addCase(AddNewClientService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default AddNewClientSlice.reducer;

