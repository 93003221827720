import { createSlice } from "@reduxjs/toolkit";
import { sucessToast } from "../toast/toast";
import { getClientByIDservice } from "../service/getClientByIdService";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const getClientByIdSlice = createSlice({
  name: " getClientByIdSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getClientByIDservice.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(getClientByIDservice.fulfilled, (state, action) => {
      // sucessToast("Broker Data Get Sucessfully");
      return { ...state,  loading: false,data:action.payload };
    });
    builder.addCase(getClientByIDservice.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default getClientByIdSlice.reducer;

