import { createSlice } from "@reduxjs/toolkit";
import { sucessToast } from "../toast/toast";
import { AddCarrierService } from "../service/addCarrierservice";

const initialState = {
  loading: false,
  error: null,
};

const AddCarrierSlice = createSlice({
  name: "AddCarrierSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(AddCarrierService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(AddCarrierService.fulfilled, (state, action) => {
      sucessToast(" Carrier Created");
      return { ...state,  loading: false };
    });
    builder.addCase(AddCarrierService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default AddCarrierSlice.reducer;

