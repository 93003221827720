import React, { useEffect, useState } from "react";
import { PhoneIcon, AddIcon, WarningIcon, ViewIcon, CopyIcon } from '@chakra-ui/icons'
import view from '../assets/view.png'
import edit from '../assets/edit.png'
import exportpng from '../assets/export.png'
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { getAllOrderService } from "../service/getAllordderSevice";
import { useDispatch, useSelector } from "react-redux";
import { exportToCSV } from "./exportCsv";
import { exportToexport } from "./exportCsv";
import { DeleteCarrierByIdService } from "../service/deleteCarrierByIdService";
const OrderHistory = () => {
    const [searchValue, setInputvalue] = useState('')
    const { data } = useSelector((state) => state?.getAllorderSlice)
    console.log(data, 'skbcjhsbcjhsbcjhbcjhcbhcb')
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllOrderService())
    }, [dispatch])

    const [filterData, setFilterData] = useState([])
    useEffect(() => {
        if (!searchValue) {
            setFilterData(data); // Reset filterData to original data when searchValue is empty
        } else {
            const newData = data.filter(item =>
                item.name && item.name.toLowerCase().includes(searchValue.toLowerCase())
            );
            setFilterData(newData); // Update filterData with filtered data
        }
    }, [searchValue, data]); // Include 'data' in dependencies array

    // Function to export data to export



    const copyToClipboard = () => {
        // Define headers
        const headers = ['Carrier ID', 'Name', 'Total Cost', 'Quantity', 'Status'];

        // Prepare the text data
        let textData = '';

        // Add headers
        textData += headers.join('\t') + '\n'; // Use tab (\t) for Copying options, newline (\n) for rows

        // Add rows from filterData
        filterData.forEach(item => {
            // Ensure each item has all the required fields
            const row = [
                item?.carrierId || '',  // Ensure default value if missing
                item?.name || '',       // Ensure default value if missing
                item?.totalCost || '',  // Ensure default value if missing
                item?.quantity || '',   // Ensure default value if missing
                item?.status || ''      // Ensure default value if missing
            ].join('\t'); // Use tab (\t) for Copying options
            textData += row + '\n'; // Add a newline for each row
        });

        // Copy text data to clipboard
        navigator.clipboard.writeText(textData).then(
            () => alert('Table data copied to clipboard!'),
            (err) => console.error('Failed to copy table data: ', err)
        );
    };
    const handleClick = async (id) => {
        try {
          // Show a confirmation dialog to the user
          const confirm = window.confirm('Are you sure you want to delete this Inventory ?');
      
          // Proceed only if the user confirmed
          if (confirm) {
            // Perform the delete operation and wait for it to complete
            await dispatch(DeleteCarrierByIdService(id)).unwrap();
      
            // Refresh the data after deletion
            dispatch(getAllOrderService());
          }
        } catch (e) {
          // Log any errors that occur during the async operations
          console.error('Error during deletion:', e);
        }
      };
      
    return (
        <>
            <style>
                {

                    `
                th{
                text-align:center;
                border-right:1px solid white;
                background:#FBB04B;
                color:white;
                }
                  td{
                text-align:center;
                }


                
                `
                }
            </style>
            <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
                <h3 className="font-medium w-[98%] mx-auto text-xl max-[768px]:text-center">Inventory</h3>
            </div>

            <div className="mt-5 w-[98%] mx-auto " style={{ background: 'rgba(243, 251, 254, 1)' }}>
                <div className="flex justify-between items-center w-[98%] p-2 mx-auto">
                    <div className="">
                        <h1 className="font-[700] " style={{ color: '#8A8D56' }}> Your Inventory</h1>
                    </div>
                    <div className="">
                        <Link to={'/numbersystem/admin/addCarrier'}>
                            <button style={{ background: '#FBB04B' }} class=" hover:bg-orange-700 text-white font-bold py-2 px-4 rounded">
                                <AddIcon w={5} h={3} boxSize={4} />     Add Inventory
                            </button>
                        </Link>
                    </div>
                </div>
            </div>


            <div className="">
                <div className="w-[98%]  max-[768px]:flex-wrap  mx-auto mt-4 flex gap-2 items-center justify-between mb-4 max-[768px]:justify-center max-[768px]:w-[94%] ">
                    <div className="w-[98%] mx-auto mt-4 flex gap-2 items-center mb-4 max-[768px]:justify-between max-[768px]:w-[94%]">
                        <div>
                            <p style={{ color: '#8A8D56' }} className="text-[#8A8D56]">Copying options</p>
                        </div>
                        <div>
                            <Button onClick={() => exportToexport(filterData)}>
                                <img src={exportpng} alt="export" />
                                Excel
                            </Button>
                        </div>
                        <div>
                            <Button onClick={() => exportToCSV(filterData)}>
                                <img src={exportpng} alt="export" />
                                CSV
                            </Button>
                        </div>
                        <div>
                            <Button onClick={copyToClipboard}>
                                <img src={exportpng} alt="export" />

                                Copy
                            </Button>
                        </div>
                    </div>
                    <div>
                        <div class='max-w-md mx-auto max-[768px]:flex '>
                            <div style={{ border: '1px solid #8A8D56' }} class="relative flex items-center w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden">
                                <div class="grid place-items-center h-full w-12 text-gray-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                    </svg>
                                </div>

                                <input
                                    onChange={(e) => setInputvalue(e.target.value)}
                                    class="peer h-full w-full outline-none text-sm text-gray-700 pr-2 "
                                    type="text"
                                    id="search"
                                    placeholder="Search something.." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <body class="w-full mx-auto bg-gray-100">
                <div class="w-full mx-auto mt-5 overflow-x-auto">
                    <div class="overflow-y-auto max-h-[50vh]">
                        <table class="w-full text-sm text-left text-gray-500">
                            <thead class="text-xs text-gray-700 uppercase  sticky top-0 z-10" style={{ background: '#FBB04B' }}>
                                <tr>
                                    <th scope="col" class="px-6 py-3">Id</th>
                                    <th scope="col" class="px-6 py-3">Carrier</th>
                                    <th scope="col" class="px-6 py-3">Area Code</th>

                                    <th scope="col" class="px-6 py-3">Quantity</th>
                                    <th scope="col" class="px-6 py-3">Status</th>
                                    <th scope="col" class="px-6 py-3">Edit</th>
                                    <th scope="col" class="px-6 py-3">Delete</th>

                                </tr>
                            </thead>
                            <tbody class="bg-white">
                                {
                                    Array.isArray(filterData) && filterData.map((item, index) => {
                                        return (
                                            <>
                                                <tr class="border-b" style={{ background: `${Number(item?.quantity) < Number(item?.altertQuantity) ? 'yellow' : ''}` }}>

                                                    <td key={index} class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">{item?.carrierId}</td>
                                                    <td class="px-6 py-4">{item?.name}</td>
                                                    <td class="px-6 py-4">{item?.areaCode}</td>

                                                    <td class="px-6 py-4">{item?.quantity}</td>
                                                    <td class={`px-6 py-4  ${item?.status == 'Active' ? 'text-green-500' : 'text-red-500'} font-medium`}>{item?.status}</td>
                                                    <td class="px-6 py-4 text-blue-500 font-medium text-xl">
                                                        <Link to={`/numbersystem/carrier/${item?.carrierId}`}>

                                                            <img src={edit} class="w-10 mx-auto" />
                                                        </Link>
                                                    </td>

                                                    <td class="px-6 py-4 text-blue-500 font-medium text-xl" >
                                                      <Button className="bg-red-500" onClick={()=>handleClick(item?.carrierId)}>Delete</Button>
                                                    </td>

                                                </tr>

                                            </>
                                        )
                                    })
                                }




                            </tbody>
                        </table>
                    </div>
                </div>
            </body>

        </>
    )
}

export default OrderHistory