import { createSlice } from "@reduxjs/toolkit";
// import { sucessToast } from "../toast/toast";

import { LoginService } from '../service/Loginservice';

const initialState = {
  data: null,
  loading: false,
  error: null,
  classNotesList: [],
};
const Authslice = createSlice({
  name: "LoginService",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(LoginService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(LoginService.fulfilled, (state, action) => {
    //   sucessToast("Login Sucessfully");
      return { ...state,  loading: false };
    });
    builder.addCase(LoginService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    
  },
});


export default Authslice.reducer;

