import { createSlice } from "@reduxjs/toolkit";
import { sucessToast } from "../toast/toast";
import { UpdateDistributorService } from "../service/updateDistributorOrderStatusService";
const initialState = {
  loading: false,
  error: null,
};

const UpdateOrderDistributorSlice = createSlice({
  name: "UpdateOrderDistributorSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(UpdateDistributorService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(UpdateDistributorService.fulfilled, (state, action) => {
      sucessToast("Order Status  Updated");
      return { ...state,  loading: false };
    });
    builder.addCase(UpdateDistributorService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default UpdateOrderDistributorSlice.reducer;

