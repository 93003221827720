import { createSlice } from "@reduxjs/toolkit";
import { sucessToast } from "../toast/toast";
import { getAllOrderService } from "../service/getAllordderSevice";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const getAllorderSlice = createSlice({
  name: "getAllorderSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllOrderService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(getAllOrderService.fulfilled, (state, action) => {
      // sucessToast("Broker Data Get Sucessfully");
      return { ...state,  loading: false,data:action.payload };
    });
    builder.addCase(getAllOrderService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default getAllorderSlice.reducer;

