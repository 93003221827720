import { createSlice } from "@reduxjs/toolkit";
import { sucessToast } from "../toast/toast";

import { AddCreditsForDistributorService } from "../service/addCreditsForDistributorService";
const initialState = {
  loading: false,
  error: null,
};

const addCreditsDistributorSlice = createSlice({
  name: "addCreditsDistributorSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(AddCreditsForDistributorService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(AddCreditsForDistributorService.fulfilled, (state, action) => {
      sucessToast("Credits  Added Sucessfully for Distributor");
      return { ...state,  loading: false };
    });
    builder.addCase(AddCreditsForDistributorService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default addCreditsDistributorSlice.reducer;

