import react, { useEffect, useState } from 'react'
import { FormControl, FormLabel, Select, Spinner } from '@chakra-ui/react'

import { PhoneIcon, AddIcon, WarningIcon, ViewIcon, MinusIcon, CopyIcon } from '@chakra-ui/icons'
import view from '../assets/view.png'
import edit from '../assets/edit.png'
import exportpng from '../assets/export.png'
import { Button, Input } from "@chakra-ui/react";
import { AddCreditService } from '../service/addCreditsService'
import { getAllCreditManagementService } from '../service/getAllcreditManagementservice'
import { useDispatch, useSelector } from 'react-redux'

import { AddCreditsForDistributorService } from '../service/addCreditsForDistributorService'
import { AddCreditsForCustomerService } from '../service/addCreditmanageForCustomerService'
import { getAllcustomerService } from '../service/getAllcustomerService'
import { getAlluserService } from '../service/getAlluserService'
import { exportToCSV } from "./exportCsv";
import { exportToexport } from "./exportCsv";
import { formatDate } from './dateString'

const CreditManagement = () => {
    const distributor = useSelector((state) => state?.getAlluserSlice.data)
    


    
    const CustomerData = useSelector((state) => state?.getAllcustomerSlice?.data)
    const ListCustomer = useSelector((state) => state?.getAllCreditmanagementSlice?.data)

    const dispatch = useDispatch()
    const [filterData, setFilterData] = useState([])
    const [searchValue, setInputvalue] = useState('')

    const [data, setData] = useState({
        clientName: '',
        desc: '',
        amount: '',
        satus: ""

    })
    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }
    useEffect(() => {
        dispatch(getAllCreditManagementService())
        dispatch(getAllcustomerService())
        dispatch(getAlluserService())
    }, [dispatch])


    useEffect(() => {
        if (!searchValue) {
            setFilterData(ListCustomer); // Reset filterData to original data when searchValue is empty
        } else {
            const newData = ListCustomer.filter(item =>
                item.name && item.name.toLowerCase().includes(searchValue.toLowerCase())||
                item.name && item.name.toLowerCase().includes(searchValue.toLowerCase())

            );
            setFilterData(newData); // Update filterData with filtered data
        }
    }, [ListCustomer, searchValue]); // Include 'data' in dependencies array


    const handleClick = async (refund) => {

        await dispatch(AddCreditsForCustomerService({data,refund})).unwrap()
        dispatch(getAllCreditManagementService())

    }
 const [refund,setrefun]=useState()

    const handleforCreditManagement = async (refund) => {
        setrefun(refund)
        await dispatch(AddCreditsForDistributorService({data,refund})).unwrap()
         dispatch(getAllCreditManagementService())
         setData('')
    }
    const [disRefund,DissetRefund]=useState()
    const handledisRefund = async (refund) => {
        await dispatch(AddCreditsForDistributorService({data,refund})).unwrap()
         dispatch(getAllCreditManagementService())
         setData('')
    }

   const handleforRefund=async(refund)=>{
    console.log(refund,'psckpk')
    
    await dispatch(AddCreditsForCustomerService({data,refund})).unwrap()
    dispatch(getAllCreditManagementService())

   }


   const copyToClipboard = () => {
    // Define headers
    const headers = ['dealerEmail', 'userType', 'createdDate'];

    // Prepare the text data
    let textData = '';

    // Add headers
    textData += headers.join('\t') + '\n'; // Use tab (\t) for Copying options, newline (\n) for rows

    // Add rows from filterData
    filterData.forEach(item => {
        // Ensure each item has all the required fields
        const row = [
            item?.dealerEmail || '',       // Ensure default value if missing
            item?.userType || '',  // Ensure default value if missing
            item?.createdDate || ''      // Ensure default value if missing
        ].join('\t'); // Use tab (\t) for Copying options
        textData += row + '\n'; // Add a newline for each row
    });

    // Copy text data to clipboard
    navigator.clipboard.writeText(textData).then(
        () => alert('Table data copied to clipboard!'),
        (err) => console.error('Failed to copy table data: ', err)
    );
};
    return (
        <>
            <style>
                {
                    `
                 th{
                background:#FBB04B;
                 }
                td{
               text-align:center; 
                }

                
                `
                }
            </style>
            <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
                <h3 className="font-medium w-[98%] mx-auto p-2 text-xl max-[768px]:text-center">Credit Management</h3>
            </div>

            <div className='grid grid-cols-2 max-[768px]:grid-cols-1 mx-auto justify-center items-center h-full mt-4'>
                <div className='rounded-[5px] max-[768px]:w-full' style={{ boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px' }}>
                    <div className='px-4 py-3 text-xl border-b-2' style={{ background: 'rgba(243, 251, 254, 1)' }}>


                        <h3 className='text-blue-500 font-medium'>Add Credits for Customer</h3>
                    </div>
                    <div className='p-5'>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Customer List</FormLabel>
                                <Select placeholder='Select Customer' name='clientName' onChange={handleChange}>
                                    {
                                  Array.isArray(CustomerData) &&      CustomerData?.map((item) => {
                                            return (
                                                <>
                                                    <option value={item?.customerId}>
                                                        {item?.customerName}
                                                    </option>
                                                </>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}></FormLabel>
                                <Input placeholder="Customer Payment Mode (in $)" name='desc' onChange={handleChange}></Input>
                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Amount</FormLabel>
                                <Input type='number' placeholder="Amount (in $)" name='amount' onChange={handleChange}></Input>
                            </FormControl>

                        </div>
                      
                        <div>
                        </div>
                        <div className='flex gap-3 mt-2 mx-1'>

                            <button type='button' onClick={()=>handleClick('Add Credits')} class="bg-[#8A8D56] hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
        
        <AddIcon />  Add Credits
        
     

                            </button>

                            <button onClick={()=>handleforRefund('Refund')} class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                <MinusIcon /> Refund
                            </button>
                        </div>

                    </div>

                </div>
             

                <div className='rounded-[5px]  max-[768px]:w-full' style={{ boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px' }}>
                    <div className='px-4 py-3 text-xl border-b-2' style={{ background: 'rgba(243, 251, 254, 1)' }}>


                        <h3 className='text-blue-500 font-medium'>Add Credits for Distributor</h3>
                    </div>
                    <div className='p-5'>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Distributor List</FormLabel>
                                <Select placeholder='Select Distributor' name='clientName' onChange={handleChange}>
                                    {
                                     Array.isArray(distributor) &&   distributor?.map((item) => {
                                            return (
                                                <>
                                                    <option value={item?.distributerId}>
                                                        {item?.distributerName}
                                                    </option>
                                                </>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}></FormLabel>
                                <Input placeholder="Customer Payment Mode (in $)" name='desc' onChange={handleChange}></Input>
                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Amount</FormLabel>
                                <Input type='number' placeholder="Amount (in $)" name='amount' onChange={handleChange}></Input>
                            </FormControl>

                        </div>
                        <div>
                        </div>
                        <div className='flex gap-3 mt-2 mx-1'>

                            <button onClick={()=>handleforCreditManagement('Credits Added')} class="bg-[#8A8D56] hover:bg-green-700 text-white font-bold py-2 px-4 rounded">

        
        <AddIcon />  Add Credits
       
                             


                            </button>


                            <button onClick={()=>handledisRefund('Refund')} class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                <MinusIcon /> Refund
                            </button>
                        </div>

                    </div>

                </div>



            </div>




            <div className="">
                <div className="w-[98%]  max-[768px]:flex-wrap  mx-auto mt-4 flex gap-2 items-center justify-between mb-4 max-[768px]:justify-center max-[768px]:w-[94%] ">
                    <div className="w-[98%] mx-auto mt-4 flex gap-2 items-center mb-4 max-[768px]:justify-between max-[768px]:w-[94%]">
                        <div>
                            <p style={{ color: '' }} className="">Copying options</p>
                        </div>
                        <div>
                            <Button onClick={()=>exportToexport(filterData)}>
                                <img src={exportpng} />
                                Excel
                            </Button>
                        </div>
                        <div>
                            <Button onClick={()=>exportToCSV(filterData)}>
                                <img src={exportpng} />
                                CSV
                            </Button>
                        </div>
                        <div>
                            <Button onClick={copyToClipboard}>
                                <img src={exportpng} />
                                Copy
                            </Button>
                        </div>
                      
                    </div>
                    <div>
                        <div class='max-w-md mx-auto max-[768px]:flex '>
                            <div style={{ border: '1px solid #8A8D56' }} class="relative flex items-center w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden">
                                <div class="grid place-items-center h-full w-12 text-gray-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                    </svg>
                                </div>

                                <input
                                    onChange={(e) => setInputvalue(e.target.value)}
                                    class="peer h-full w-full outline-none text-sm text-gray-700 pr-2 "
                                    type="text"
                                    id="search"
                                    placeholder="Search something.." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <body class="w-full mx-auto bg-gray-100">
                <div class="w-full mx-auto mt-5 overflow-x-auto">
                    <div class="overflow-y-auto max-h-[50vh]">
                        <table class="w-full text-sm text-left text-gray-500">
                            <thead class="text-xs text-gray-700 uppercase bg-gray-300 sticky top-0 z-10">
                                <tr>
                                    <th scope="col" class="px-6 py-3">Id</th>
                                    <th scope="col" class="px-6 py-3">Name</th>
                                    <th scope="col" class="px-6 py-3">Description</th>
                                    <th scope="col" class="px-6 py-3">Amount</th>
                                    <th scope="col" class="px-6 py-3">Status</th>

                                    <th scope="col" class="px-6 py-3">Created At</th>
                                    <th scope="col" class="px-6 py-3">User Type</th>

                                    <th scope="col" class="px-6 py-3">Dealer Email</th>


                                </tr>
                            </thead>
                            <tbody class="bg-white">
                                {
                                    Array.isArray(filterData) && filterData?.map((item,index) => {
                                        return (
                                            <>
                                                <tr class="border-b">
                                                    <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">{index+1}</td>
                                                    <td class="px-6 py-4">{item?.name}</td>
                                                    <td class="px-6 py-4">{item?.description}</td>
                                           
                                                    <td className={`px-6 py-4 font-medium ${item.status === 'Refund' ? 'text-red-500' : 'text-green-500'}`}>
 $ {item?.amount}
</td>

                                                    <td class="px-6 py-4 font-medium" >{item?.status}  </td>

                                                    <td class="px-6 py-4">{formatDate(item?.createdDate)}  </td>
                                                    <td class="px-6 py-4">{item?.userType}  </td>


                                                    <td class="px-6 py-4">{item?.dealerEmail}</td>

                                                </tr>
                                            </>
                                        )
                                    })
                                }


                            </tbody>
                        </table>
                    </div>
                </div>
            </body>

        </>
    )
}

export default CreditManagement