import { createSlice } from "@reduxjs/toolkit";
import { sucessToast } from "../toast/toast";
import { editHistoryService } from "../service/editHistoryService";

const initialState = {
  loading: false,
  error: null,
};

const EdithistorySlice = createSlice({
  name: "EdithistorySlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(editHistoryService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(editHistoryService.fulfilled, (state, action) => {
      // sucessToast("Broker Data Get Sucessfully");
      return { ...state,  loading: false };
    });
    builder.addCase(editHistoryService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default EdithistorySlice.reducer;

