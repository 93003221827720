import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { BASE_URL } from "../auth/baseUrl";
import { handleError } from "../utils/handleError";
import { basicAuth } from "../auth/basicauth"; // Import basicAuth from the appropriate location

import { errorToast, sucessToast } from "../toast/toast";

export const UploadFileService = createAsyncThunk(
  "UploadFileService",
  async (payload) => {
    console.log(payload,'scjnkc')
    try {
        
        const formData=new FormData()
        formData.append('file',payload?.file)
        formData.append('orderId',payload?.orderId)


  
      let url = BASE_URL + `importSimCardList`;
      const config = {
        headers: {
          "Authorization": basicAuth, // Include basic authentication header
          "ngrok-skip-browser-warning" : "skip-browser-warning",
        }
      };
      const res = await axios.post(url,formData, config); // Pass the config object as the third parameter

     if (res?.data?.includes('Remaining quantity')) {
      errorToast('There is an error: the given data is more than the order quantity or there is duplicate data.');
    } else if (res?.data?.includes('sufficient')) {
      errorToast('Inventory is not sufficient to complete this order.');
    } else if (res?.data?.includes('Duplicate phone number')) {
      errorToast('Error processing cell: Duplicate phone number found in the same export sheet.');
    }
    else {
      sucessToast(res.data);
    }
      return res.data;
    } catch (error) {
      console.log(error)
      errorToast('Duplicate Data in export sheet.');
      
      handleError(error); // Pass the entire error object to the handleError function
      throw error;
    }
  }
);
