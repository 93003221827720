import react, { useEffect, useState } from 'react'
import { FormControl, FormLabel, Input, Select, Spinner } from '@chakra-ui/react'
import { AddIcon, EmailIcon } from '@chakra-ui/icons'
import { AddCustomerService } from '../service/addCustomerService'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getCustomerByIdService } from '../service/getCustomerByIDservice'
import { editCustomerService } from '../service/editCustomerService'
const UpdateCustomer=()=>{
    const {loading}=useSelector((state)=>state?.editCustomerSlice)
    const newData=useSelector((state)=>state?.getCustomerByIdSlice?.data)
    console.log(newData,'dkdjcj')
    const [data,setData]=useState({
        name:"",
        email:"",
        ConfirmPassword:"",
        number:"",
        paymentMode:"",
        balance:"",
        status:"",
        password:""

    })
    const {id}=useParams()
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const handleChange=(e)=>{
        const {name,value}=e.target
        setData({
            ...data,
            [name]:value
        })
    }
    useEffect(()=>{
    dispatch(getCustomerByIdService(id))
    },[id])
    useEffect(()=>{
        setData({
            name:newData?.customerName,
            email:newData?.customerEmail,
            password:"",
            ConfirmPassword:"",
            number:newData?.phoneNumber,
            paymentMode:newData?.paymentMode,
            balance:newData?.balance,
            password:newData?.password,
            status: JSON.stringify(newData?.acountStatus)
        })

    },[newData])
    const handleCllick=async(e)=>{
        e.preventDefault()
        const newData={
            data:data,
            id:id
        }
        const ndata= dispatch(editCustomerService(newData)).unwrap()
        if(ndata.status==='Customer added successfully'){
         navigate('/numbersystem/CustomerPanel')
        }
         
    }
    return(
        <> <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
        <h3 className="font-medium w-[98%] mx-auto text-xl max-[768px]:text-center">Customer Panel</h3>
    </div>
          <div className='flex mx-auto justify-center items-center h-full mt-4'>
                <div className='rounded-[5px] w-[60%] max-[768px]:w-[90%]' style={{ boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px' }}>
                    <div className='px-4 py-3 text-xl border-b-2' style={{ background: 'rgba(243, 251, 254, 1)' }}>


                        <h3 className='text-[#8A8D56] font-medium'>Information</h3>
                    </div>
                    <div className='p-4'>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Customer  Name</FormLabel>
                                <Input value={data?.name} onChange={handleChange} placeholder='Name' name="name"></Input>
                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Customer  Email</FormLabel>
                                <Input type='email' value={data?.email} onChange={handleChange} placeholder='Email' name='email'></Input>

                            </FormControl>

                        </div>
                       
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Customer  Number</FormLabel>
                                <Input value={data.number} placeholder=' Number' onChange={handleChange} name='number'></Input>

                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Customer  Type </FormLabel>
                                <Select 
               value={data?.paymentMode==="POST_PAID"?"true":"false"}
               onChange={handleChange}
                  name="paymentMode"
                  >
                       <option value="">
Select
                </option>
                <option value="true">
Postpaid
                </option>
                <option value="false">
                Prepaid
                </option>
               </Select>
                            </FormControl>

                        </div>
                        {/* <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Customers Payment Amount (in $)
                                </FormLabel>
                                <Input value={data?.balance} placeholder='Customers Payment Amount (in $)' onChange={handleChange} name='balance'></Input>

                            </FormControl>

                        </div> */}
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Customer  Status</FormLabel>
                                <Select  placeholder='Status' onChange={handleChange} value={data?.status} name='status'>
                                    <option value={'true'}>Active</option>
                                    <option value={'false'}>Inactive</option>
                                </Select>

                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Password</FormLabel>
                                <Input value={data?.password} placeholder='Password' onChange={handleChange} name='password'></Input>

                            </FormControl>

                        </div>
                        <button  onClick={handleCllick} class="bg-[#8A8D56] hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-3">
                         
                         
                         {
                            loading?(
                                <>
                                <Spinner/>
                                </>
                            ):(
                                <>
                          <AddIcon/>  Save Customer
                                
                                </>
                            )
                         }
                        </button>
                        <div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateCustomer