import { createSlice } from "@reduxjs/toolkit";
import { sucessToast } from "../toast/toast";
import { UpdatePriceDisService } from "../service/updatenewPriceservice";
const initialState = {
  loading: false,
  error: null,
};

const UpdatePriceSlice = createSlice({
  name: "UpdatePriceSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(UpdatePriceDisService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(UpdatePriceDisService.fulfilled, (state, action) => {
      sucessToast(" Price Status Updated");
      return { ...state,  loading: false };
    });
    builder.addCase(UpdatePriceDisService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default UpdatePriceSlice.reducer;

