import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { BASE_URL } from "../auth/baseUrl";
import { handleError } from "../utils/handleError";
import { basicAuth } from "../auth/basicauth"; // Import basicAuth from the appropriate location


export const AddCreditService = createAsyncThunk(
  "AddCreditService",
  async (id) => {
    const data={
      "description":data?.Description,
      "amount":data?.amount,
      "createdDate":null,
      "updatedDate":null,
      "passCustomer":{
          "customerId":data?.clientName
      }
  }
    try {
      let url = BASE_URL + `addManageCreditByCustomerId/${id}`;
      const config = {
        headers: {
          "Authorization": basicAuth, // Include basic authentication header
          'Content-Type': 'application/json',
          "ngrok-skip-browser-warning" : "skip-browser-warning",
        }
      };
      const res = await axios.post(url, data, config); // Pass the config object as the third parameter
      console.log(res)
      return res.data;
    } catch (error) {
      console.log(error)
      handleError(error); // Pass the entire error object to the handleError function

      throw error;
    }
  }
);
