import React, { useEffect, useState, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { MenuItem, MenuList, MenuButton, Menu, Button, DrawerOverlay, DrawerCloseButton, DrawerContent, DrawerBody, DrawerHeader, Drawer, useDisclosure } from '@chakra-ui/react'
import logo from '../assets/logo2.png'
import { emitter } from '../dashboard/emit'
const Header = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const buttonRef = useRef(null)
  useEffect(() => {
    const links = document.querySelectorAll('.item');
    links.forEach(link => {
      if (link.pathname === location.pathname) {
        link.classList.add('active');
      } else {
        link.classList.remove('active');
      }
    });
  }, [location]);


  const [isOpen1, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);


  const toggleDropdown = () => {
    setIsOpen(!isOpen1);
  };
  const handleMouseDown = (event) => {
    if (dropdownRef.current && buttonRef.current) {
      const { top: dropdownTop, left: dropdownLeft, width: dropdownWidth, height: dropdownHeight } = dropdownRef.current.getBoundingClientRect();
      const { top: buttonTop, left: buttonLeft, width: buttonWidth, height: buttonHeight } = buttonRef.current.getBoundingClientRect();
      const { clientX: mouseX, clientY: mouseY } = event;

      // Calculate proximity distance to the dropdown and button
      const isNearDropdown =
        mouseX >= dropdownLeft - 10 &&
        mouseX <= dropdownLeft + dropdownWidth + 10 &&
        mouseY >= dropdownTop - 10 &&
        mouseY <= dropdownTop + dropdownHeight + 10;

      const isNearButton =
        mouseX >= buttonLeft - 10 &&
        mouseX <= buttonLeft + buttonWidth + 10 &&
        mouseY >= buttonTop - 10 &&
        mouseY <= buttonTop + buttonHeight + 10;

      // Close dropdown if the cursor is not near dropdown or button
      if (!isNearDropdown && !isNearButton) {
        setIsOpen(false);
      }
    }
  };
  useEffect(() => {
    if (isOpen1) {
      // Add event listener to track mouse movement
      document.addEventListener('mousemove', handleMouseDown);
    } else {
      // Remove event listener if dropdown is not open
      document.removeEventListener('mousemove', handleMouseDown);
    }

    // Clean up event listener when the component unmounts or when isOpen changes
    return () => {
      document.removeEventListener('mousemove', handleMouseDown);
    };
  }, [isOpen1]);

  const [size, setSize] = React.useState('')

  const handleClick = (newSize) => {
    setSize(newSize)
    onOpen()
  }


  const handleLogout = () => {
    localStorage.removeItem('adminId')
    emitter.emit("logout");
  }

  return (
    <>
      <style>
        {
          `   
          .drwaserdrawer h3{
          background:aliceblue;
          margin-top:10px;
          padding-left:20px;

          }
                .item.active{
                color:orange;
                border-bottom:1px solid orange;

                }
                .item:hover{
                color:#8A8D56;
                border-bottom:1px solid orange;
                }
                @media(max-width:768px){
                .rounded-\[5px\].w-\[60\%\] {
      width: 90%;
  }
                }
                `
        }
      </style>
      <div className='w-full max-[768px]:hidden'>
        <div className='flex justify-around w-[98%] mx-auto mt-4 flex-wrap items-center'>
          <div className=''>
            <h3 className='font-[500]' style={{ width: '100px', height: '' }}>
              <Link to={`/numbersystem/admin/`}>
                <img src={logo} />
              </Link>
            </h3>
          </div>
          <div className=''>
            <Link to='/numbersystem/admin' className='item'>
              <h3 className='font-[500]'>Dashboard</h3>
            </Link>

          </div>
          <div className=''>
            <Link to='/numbersystem/admin/Inventory' className='item'>
              <h3 className='font-[500]'>Inventory Management</h3>
            </Link>
          </div>
          <div className=''>
            <Link
              to="/numbersystem/admin/ordermanagement"
              className='item'
              role="menuitem"
              onClick={() => setIsOpen(false)}
            >
              <h3 className='font-[500]'>    Distributor Order History</h3>

            </Link>
          </div>
          <div className=''>

            <Link
              className='item'
              to="/numbersystem/admin/CustomerOrderHistory"
              role="menuitem"
              onClick={() => setIsOpen(false)}
            >
              <h3 className='font-[500]'>   Customer Order History</h3>
            </Link>
          </div>

          <div className='' >

            <div className="relative inline-block text-left">
              <div>

                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4  text-sm font-medium t rounded-md  focus:outline-none"
                  id="options-menu"
                  aria-haspopup="true"
                  aria-expanded="true"
                  onClick={toggleDropdown}
                  ref={buttonRef} // Attach ref to the button

                >
              < h3 className='font-[500] text-[17px]'>
               Menu
                </h3>   

                </button>
              </div>

              {isOpen1 && (
                <div
                  className="z-40 origin-top-right z-10 absolute right-0  w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                  role="menu"
                  ref={dropdownRef} // Attach ref to the dropdown menu
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  <div className="py-1" role="none">


                    <div className=''>
                      <Link to='/numbersystem/admin/CustomerPanel' className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">

                        <h3 className=''
                          onClick={() => setIsOpen(false)}


                        >Customer Management</h3>
                      </Link>
                    </div>

                    <div className=''>
                      <Link to='/numbersystem/admin/userManagement' className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        <h3
                          onClick={() => setIsOpen(false)}

                          className=''>Distributer Management</h3>
                      </Link>
                    </div>



                    <Link
                      to="/numbersystem/admin/creditmanagement"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      onClick={() => setIsOpen(false)}
                    >
                      Credit Management
                    </Link>
                    <Link
                      to="/numbersystem/admin/OrderTracking"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      onClick={() => setIsOpen(false)}
                    >
                      Customer   Order Tracking
                    </Link>
                    <Link
                      to="/numbersystem/admin/DistributorOrderTracking"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      onClick={() => setIsOpen(false)}
                    >
                      Distributer   Order Tracking
                    </Link>
                    <Link
                      to="/numbersystem/admin/Faq"
                      className="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      onClick={() => setIsOpen(false)}
                    >
                      FAQ
                    </Link>

                    <Link
                      to="/numbersystem/admin/PrivacyPolicy"
                      className="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      onClick={() => setIsOpen(false)}
                    >
                      Privacy Policy
                    </Link>

                    <Link
                      to="/numbersystem/admin/termscondition"
                      className="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      onClick={() => setIsOpen(false)}
                    >
                      Terms & Condition
                    </Link>
                    <Link
                      to="/numbersystem/admin/ReturnExchange"
                      className="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      onClick={() => setIsOpen(false)}
                    >
                      Return & Exchange Policy                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className=''>
            <h3 className='font-[500] cursor-pointer' onClick={handleLogout}>Logout</h3>
          </div>
          <div>
            <h3 className='font-bold'>
              Welcome  Admin 
            </h3>
          </div>
        </div>
      </div>






      {/* mobile */}

      <div className='w-full bg-[aliceblue] p-3 block md:hidden font-[500]'>
        <div className=' max-[768px]:block  items-center  justify-between w-11/12 mx-auto ' style={{ display: 'flex' }}>
          <div>
            <h3 className="" style={{ width: '100px' }}>
              <img src={logo} alt="Logo" />
            </h3>
          </div>
          <div>
            <Button
              onClick={() => handleClick('xs')}
              key={'xs'}
            >
              Menu
            </Button>
          </div>
        </div>
      </div>
      <Drawer onClose={onClose} isOpen={isOpen} size={size} className='hidden max-[768px]:block'>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Hi Admin</DrawerHeader>
          <DrawerBody lineHeight={'45px'} padding={'0px'} className='drwaserdrawer'>
            <div className='' onClick={onClose}  >
              <Link to='/numbersystem/admin' className='item'>

                <h3 className='font-[500]'  >Dashboard</h3>
              </Link>

            </div>
            <div className='' onClick={onClose}>
              <Link to='/numbersystem/admin/Inventory' className='item'>
                <h3 className='font-[500]' >Inventory Management</h3>
              </Link>
            </div>
            <div className='' onClick={onClose}>
              <Link to='/numbersystem/admin/CustomerPanel' className='item'>

                <h3 className='font-[500]'>Customer Management</h3>
              </Link>
            </div>
            <div className='' onClick={onClose}>
              <Link to='/numbersystem/admin/userManagement' className='item'>
                <h3 className='font-[500]'>Distributer Management</h3>
              </Link>
            </div>

            <div className="" role="none" >



              <Link
                onClick={onClose}
                to="/numbersystem/admin/ordermanagement"
                className="item"
                role="menuitem"
              >
                <h3 className='font-[500]'>    Distributor Order History</h3>

              </Link>
              <Link
                to="/numbersystem/admin/CustomerOrderHistory"
                className="item"
                role="menuitem"
                onClick={onClose}

              >
                <h3 className='font-[500]'>     Customer Order History</h3>

              </Link>
              <Link
                to="/numbersystem/admin/OrderTracking"
                className="item"
                role="menuitem"
                onClick={onClose}

              >
                <h3 className='font-[500]'>         Order Tracking</h3>
              </Link>
              <Link
                to="numbersystem/admin/creditmanagement"
                className="item"
                role="menuitem"
                onClick={onClose}

              >
                <h3 className='font-[500]'>      Credit Management</h3>
              </Link>
              <Link
                to="/numbersystem/admin/Faq"
                className="item"
                role="menuitem"
                onClick={onClose}

              >
                <h3 className='font-[500]'>      FAQ</h3>
              </Link>

              <Link
                to="/numbersystem/admin/PrivacyPolicy"
                className="item"
                role="menuitem"
                onClick={onClose}

              >
                <h3 className='font-[500]'>      Privacy Policy</h3>
              </Link>
              <Link
                to="/numbersystem/admin/termscondition"
                className="item"
                role="menuitem"
                onClick={onClose}

              >
                <h3 className='font-[500]'>   Terms&Condition</h3>
              </Link>
              <Link
                to="/numbersystem/admin/ReturnExchange"
                className="item"
                role="menuitem"
                onClick={onClose}

              >
                <h3 className='font-[500]'>     Return & Exchange Policy</h3>
              </Link>
            </div>
            <div className=''>
              <h3 className='font-[500] cursor-pointer' onClick={handleLogout}>Logout</h3>
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

    </>
  )
}

export default Header