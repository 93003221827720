import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register the components needed for the chart
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// Function to generate day names for the last 7 days including today
const getDayLabels = () => {
  const labels = [];
  const now = new Date();

  // Generate labels for the last 7 days
  for (let i = 6; i >= 0; i--) {
    const date = new Date(now);
    date.setDate(now.getDate() - i);
    const dayName = date.toLocaleDateString('en-US', { weekday: 'long' });
    labels.push(dayName);
  }

  return labels;
};

// Function to aggregate data by carrier and day
const aggregateCarrierOrdersByDay = (weekdata) => {
  const carrierOrdersByDay = {};

  weekdata.forEach((dayData) => {
    const dayName = dateToDayName(dayData.date);
    carrierOrdersByDay[dayName] = carrierOrdersByDay[dayName] || {};

    dayData.carriers.forEach((carrier) => {
      const { carrierName, quantity } = carrier;
      if (carrierOrdersByDay[dayName][carrierName]) {
        carrierOrdersByDay[dayName][carrierName] += quantity;
      } else {
        carrierOrdersByDay[dayName][carrierName] = quantity;
      }
    });
  });

  return carrierOrdersByDay;
};

// Function to convert date string to day name
const dateToDayName = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', { weekday: 'long' });
};


const WeekChart = ({ weekdata }) => {
  // Get day names for the last 7 days
  const labels = getDayLabels();
  // Aggregate orders by carrier and day
  const carrierOrdersByDay = aggregateCarrierOrdersByDay(weekdata);

  // Collect all unique carrier names
  const allCarriers = new Set();
  Object.values(carrierOrdersByDay).forEach(dayData => {
    Object.keys(dayData).forEach(carrierName => allCarriers.add(carrierName));
  });

  const carrierColors = {
    'Metro by T-Mobile': {
      backgroundColor: 'rgba(160, 32, 240, 0.2)', // #a020f0
      borderColor: 'rgba(160, 32, 240, 1)',
    },
    'Verizon': {
      backgroundColor: 'rgba(240, 32, 32, 0.2)', // #f02020
      borderColor: 'rgba(240, 32, 32, 1)',
    },
  };

  // Prepare datasets for each carrier
  const datasets = Array.from(allCarriers).map((carrierName) => {
    const { backgroundColor, borderColor } = carrierColors[carrierName] || {
      backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.2)`,
      borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`,
    };

    return {
      label: carrierName,
      data: labels.map((label) => carrierOrdersByDay[label]?.[carrierName] || 0),
      backgroundColor,
      borderColor,
      borderWidth: 1,
    };
  });
  // Chart data and options
  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  return (
    <div style={{ width: '70%', height: '300px', margin: '0 auto' }}>
      <div className="flex  mt-5">
        <h3 className="font-medium text-2xl">
        Your   Weekly  Details

        </h3>
      </div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default WeekChart;
